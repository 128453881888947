<template>
  <div>
    <div class="form-group">
      <label class="form-label d-block" for="form1">Search</label>
      <div class="input-group">
        <input type="search" id="form1" class="form-control" v-model="phone" placeholder="Enter Phone Number to Search"
               @keyup.prevent="searchPhone" />
        <button type="button" class="btn btn-danger" @click.prevent="searchPhone">
          <i class="ti-search"></i>
        </button>
      </div>
    </div>

    <div class="customer-account" v-show="status === 204">
      <div class="row">
        <div class="col-12">
          No customer found for this phone no ({{ phone }})
        </div>
      </div>
    </div>

    <div class="customer-account" v-show="customer.msisdn">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-center pt-0">
              <img class="u-avatar-lg u-avatar-minus-half-offset rounded-circle mb-4" src="/assets/images/user-unknown.jpg" alt="Avatar">
              <h3 class="mb-1">{{ customer.first_name }} {{ customer.surname }}</h3>
              <h5 class="text-muted mb-4">{{ customer.network }}</h5>
            </div>


            <div class="card-footer border-top p-0">
              <!-- Buttons -->
              <div class="row no-gutters">
                <div class="col-6 border-right">
                  <a v-if="parseInt(customer.receive_promotional_sms) === 1" class="btn btn-link btn-with-icon btn-block font-weight-semi-bold py-4" href="javascript:void(0)" @click.prevent="disableBulk">
                    Disable Bulk
                  </a>
                  <a v-else class="btn btn-link btn-with-icon btn-block font-weight-semi-bold py-4" href="javascript:void(0)" @click.prevent="enableBulk">
                    Enable Bulk
                  </a>
                </div>
                <div class="col-6 border-right">
                  <a class="btn btn-link btn-with-icon btn-block font-weight-semi-bold py-4" href="javascript:void(0)" @click="toggleBlock">
                    Block
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-left px-3 px-md-5">
              <h3 class="mb-4">History</h3>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Total Responses</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span> {{ accounting.format(customer.total_bet_stake, 2) }} </h6>
              </div>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Deposit Count</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span> {{ customer.deposit_count }}</h6>
              </div>

              <div class="person-transactions d-flex justify-content-between">
                <h5 class="text-dark"> First / Last Response Date</h5>
                <h6 class="person-transactions" v-show="customer.first_deposit_date"><span class="pl-1 amount-transacted"></span>
                  {{ moment( customer.first_deposit_date).format('lll') }} / {{ moment(customer.last_deposit_date).format('lll')}}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  Modal | Schedule -->
    <sui-modal v-model="open">
      <sui-modal-header>Reconcile Payment</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Mpesa Code</label>
              <input placeholder="Mpesa Code" type="text"/>
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="toggle"> Cancel </sui-button>
        <sui-button color="blue" @click.prevent="toggle"> Submit </sui-button>
      </sui-modal-actions>
    </sui-modal>
    <!--  Modal | Block -->
    <sui-modal v-model="block">
      <sui-modal-header>Block User</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Reason</label>
              <sui-dropdown
                  placeholder="Reason"
                  selection
                  search
                  :options="items"
              />
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="toggleBlock"> Cancel </sui-button>
        <sui-button color="blue" @click.prevent="toggleBlock"> Submit </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import accounting from "accounting";
import moment from "moment";
import {mapActions} from "vuex";

export default {
  name: "customer-profile",
  data() {
    return {
      open: false,
      block: false,
      phone: null,
      customer: {},
      status: null,
      accounting: accounting,
      moment: moment,
      moreParams: {},
      items: [
        { text: 'Fraud', value: 1 },
        { text: 'Self', value: 2 },
      ]
    }
  },
  mounted() {
    this.initCustomer()
  },
  methods: {
    ...mapActions(["fillMsisdn", "fillCustomer", "searchCustomerProfile", "disableEnableBulk", "fillMsisdn"]),

    async setCustomer(params) {
      let response = await this.searchCustomerProfile(params)
      this.customer = response.message
      this.status = response.status
      if (response.status !== 204){
        await this.fillCustomer(response.message)
        this.$parent.reload()
      }
      else {
        await this.fillCustomer({})
      }
    },

    initCustomer(){
      this.customer = this.$store.state.customer
      if (this.customer.msisdn){
        this.$parent.reload()
      }
      //this.customer = this.$store.state.customer
    },

    toggle() {
      this.open = !this.open
    },

    toggleBlock() {
      this.block = !this.block
    },

    async searchPhone(){
      let app = this
      if (this.phone.length > 0){
        this.$parent.customer = false
        if (this.phone.slice(0, 1) === '0') this.phone = this.phone.slice(1)
        this.moreParams.msisdn = this.phone
        await this.fillMsisdn(this.phone)
        await app.setCustomer(app.moreParams)
      }
    },

    async disableBulk() {
      let app = this
      const msisdn = this.phone
      let payload = { msisdn: msisdn, status: 0 }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this disables bulk sms for this user !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, disable!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.disableEnableBulk(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {

              app.$swal.fire('Disabled!', result.value.message, 'success')
              app.searchPhone()
            }
            else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async enableBulk() {
      let app = this
      const msisdn = this.phone
      let payload = { msisdn: msisdn, status: 1 }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this enables bulk sms for this user !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, enable!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.disableEnableBulk(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {

              app.$swal.fire('Enabled!', result.value.message, 'success')
              app.searchPhone()
            }
            else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

  }
}
</script>

<style scoped>

</style>
