<template>
  <div class="wrapper">
    <side-bar></side-bar>

    <!-- Main -->
    <main id="content">
      <top-bar></top-bar>
      <main class="u-main">
        <!-- Content -->
        <div class="u-content">
          <router-view></router-view>
        </div>
      </main>
    </main>
  </div>
</template>
<script>
import TopBar from "@/components/top-bar";
import moment from "moment";
import SideBar from "@/components/aside";
export default {
    data(){
        return{}
    },
    components: {
      SideBar,
      TopBar,
    },
    mounted(){
    },
    methods: {
    }
}
</script>
<style>
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: transparent!important;
}
</style>
