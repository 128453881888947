<Pie
    :chart-data="chartdata"
    :options="options"
    :styles="styles"
    :width="width"
    :height="height">
</Pie>

<script>
import {Pie} from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    styles: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            onHover: this.handleHover,
            onLeave: this.handleLeave
          },
          title: {
            display: true,
            text: 'Client Events'
          }
        }
      },
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  methods: {
    handleLeave(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
      });
      legend.chart.update();
    },
    handleHover(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
        colors[index] = index === item.index || color.length === 9 ? color : color + '4D';
      });
      legend.chart.update();
    }
  }
}
</script>

<style scoped>

</style>
