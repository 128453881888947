<bar-chart :chart-data="chartdata" :options="options"></bar-chart>

<script>
import {Bar} from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
            onHover: this.handleHover,
            onLeave: this.handleLeave
          },
          title: {
            display: true,
            text: 'Client Events'
          }
        },
        scales: {
          y: {
            min: 0,
          }
        }
      },
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  methods: {
    handleLeave(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
        colors[index] = color.length === 9 ? color.slice(0, -2) : color;
      });
      legend.chart.update();
    },
    handleHover(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
        colors[index] = index === item.index || color.length === 9 ? color : color + '4D';
      });
      legend.chart.update();
    }
  }
}
</script>

<style scoped>

</style>
