<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <div class="row py-3">
        <div class="col-6">
          <h3>Logs</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-left px-3 px-md-5  py-3 py-md-3">
              <h3 class="mb-4">Login Stats</h3>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Login Attempts (Successful/Failed)</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span>
                  {{ user_login.success_attempts }} / {{ user_login.cumulative_failed_attempts }}
                </h6>
              </div>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Last Login (Successful/Failed)</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span>
                  {{ moment(user_login.last_successful_login_date).format('lll') }} / {{ moment(user_login.last_failed_login_date).format('lll') }}
                </h6>
              </div>

              <div class="person-transactions mb-2 d-flex justify-content-between">
                <h5 class="text-dark"> Created / Activation Date</h5>
                <h6 class="person-transactions"> <span class="pl-1 amount-transacted"></span> {{ moment( user_login.created_at).format('lll') }} / {{ moment(user_login.activation_date).format('lll')}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="card mb-5">

            <div class="card-body text-left px-3 px-md-5  py-3 py-md-3">
              <h3 class="mb-4">{{ role }}</h3>
            </div>
          </div>
        </div>
      </div>

      <!-- Card -->
      <div class="card mb-5">
        <!-- Crad Body -->
        <div class="card-body pt-0">
          <!-- Table -->
          <div class="table-responsive">
            <div class="row ml-3">
              <h3 class="my-4">Login History</h3>
              <loader v-show="loading"/>
              <vuetable ref="vuetable"
                        :api-url="url"
                        :fields="fields"
                        :per-page="perPage"
                        track-by="id"
                        :append-params="moreParams"
                        :httpOptions="httpOptions"
                        data-path="data.data.audit_logs.data"
                        pagination-path="data.data.audit_logs"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
              >
              </vuetable>
              <div style="padding-top:10px">
                <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "logs",

  data() {
    return {
      loading: true,
      fields: [
        {
          name: "action",
          title: '<span class="orange glyphicon glyphicon-user"></span> Actions',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "description",
          title: "Description",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "created",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          dataClass: 'no-border'
        }
      ],
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      url: this.$store.state.api + 'admin/v1/logs',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 10,
      data: [],
      permissions: [],
      user_login: {},
      role: '',
      moment: moment
    };
  },

  mounted() {

    var vm = this;

    vm.setLogs()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getUserLogs"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setLogs() {
      let response = await this.getUserLogs()
      this.data = response.message.audit_logs
      this.role = response.message.role
      this.permissions = response.message.permissions
      this.user_login = response.message.user_login
    },
  }
};
</script>
