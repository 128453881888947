<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <transition name="fade">
        <sui-message
            v-if="visible"
            :content="message"
            dismissable
            @dismiss="handleDismiss"
            negative
        />
      </transition>
      <!-- Card -->
      <div class="row pb-3">
        <div class="col-6">
          <h3>{{ app_name }}</h3>
        </div>
        <div class="col-6 text-right">
          <sui-button primary content="Send Message" @click.native="toggleSms"></sui-button>
          <sui-button positive content="Start Survey" @click.native="toggle" v-if="!active"></sui-button>
          <sui-button negative content="Stop Survey" v-else @click.prevent="stopSurvey"></sui-button>
        </div>
      </div>
      <div>
        <sui-tab :menu="{ pointing: true }">
          <sui-tab-pane title="Responses" :attached="false">
            <sui-segment basic v-if="question.options">
              <sui-statistic floated="right" v-if="parseInt(question.survey_question_type_id) !== 1">
                <sui-segment piled compact>
                  Options
                  <div class="row">
                    <div class="col-auto">
                      <ol class="list-shrink">
                        <li class="item" v-for="item in question.options.slice(0, 3)">{{ item.question_option }}</li>
                      </ol>
                    </div>
                    <div class="col-auto">
                      <ol class="list-shrink" start="4">
                        <li class="item" v-for="item in question.options.slice(3, 6)">{{ item.question_option }}</li>
                      </ol>
                    </div>
                    <div class="col-auto">
                      <ol class="list-shrink" start="7">
                        <li class="item" v-for="item in question.options.slice(6, question.options.length)">{{ item.question_option }}</li>
                      </ol>
                    </div>
                  </div>
                </sui-segment>
              </sui-statistic>
              <p>
                <span class="lh-30"><strong>Section: </strong>{{ question.questionaire_name }}</span> <br>
                <span class="lh-30"><strong>Question: </strong>{{ question.question }}</span> <br>
                <span class="lh-30"><strong>Type: </strong>{{ question.question_type }}</span>
              </p>
            </sui-segment>
            <sui-divider />
            <div class="row py-2">
              <div class="col-12 pb-2">
                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <sui-segment>
                      <div class="text-center">General Stats</div>
                      <div class="row">
                        <div class="col-12">
                          <pie-chart :chartdata="chartDataStats" :key="uniqueStats"/>
                        </div>
                      </div>
                    </sui-segment>
                  </div>
                  <div class="col-md-8 col-sm-12">
                    <div class="row">
                      <div class="col-12 pb-2">
                        <div class="row d-flex justify-content-between">
                          <div class="col">
                            <sui-button-group>
                              <sui-button content="Respondents" @click="switchResponse(0)"/>
                              <sui-button content="Non Respondents" @click="switchResponse(1)"/>
                            </sui-button-group>
                          </div>
                          <div class="col text-right">
                            <sui-dropdown text="Action" button pointing>
                              <sui-dropdown-menu>
                                <sui-dropdown-item>
                                  <vue-json-to-csv :json-data="r_data" csv-title="RESPONSE DATA CSV">
                                    Export
                                  </vue-json-to-csv>
                                </sui-dropdown-item>
                                <sui-dropdown-item @click.prevent="sendBulkQuiz(question.id)">Send Bulk</sui-dropdown-item>
                              </sui-dropdown-menu>
                            </sui-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <loader v-show="loading"/>
                        <vuetable ref="vuetable"
                                  :api-url="url"
                                  :fields="fields"
                                  :per-page="perPage"
                                  track-by="id"
                                  :append-params="moreParams"
                                  :httpOptions="httpOptions"
                                  data-path="data.data.data"
                                  pagination-path="data.data"
                                  @vuetable:pagination-data="onPaginationData"
                                  @vuetable:loading="onLoading"
                                  @vuetable:loaded="onLoaded"
                        >
                        </vuetable>
                        <div style="padding-top:10px">
                          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <sui-divider />
                <p class="text-center"><strong>Progress of Completed Survey Response</strong></p>
                <sui-progress state="active" size="standard" indicating progress :percent="percent"/>
                <p class="text-center">{{ label }}</p>
                <sui-divider />
              </div>
            </div>
            <div class="row mb-3">
              <div v-show="current_page > 1" class="col text-left">
                <sui-button content="Previous Quiz" primary @click.prevent="currentPage--"></sui-button>
              </div>
              <div v-show="current_page < last_page" class="col text-right">
                <sui-button content="Next Quiz" primary @click.prevent="currentPage++"></sui-button>
              </div>
            </div>
          </sui-tab-pane>
          <sui-tab-pane title="Reports" :attached="false">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-xl-3 mb-5" v-if="total_recipients">
                <card-stats title="Contacts" :value="total_recipients.toString()" color="#f12559"></card-stats>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-3 mb-5">
                <card-stats title="Rewards" :value="stats.reward.toString()" color="#f1be25"></card-stats>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-3 mb-5">
                <card-stats title="Responses" :value="stats.response.toString()" color="#28a745"></card-stats>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-3 mb-5" v-for="stat in stats.types">
                <card-stats :title="stat.identifier_name" :value="stat.response" color="#1a203b"></card-stats>
              </div>
            </div>
            <sui-divider />
            <div class="row">
              <div class="col-12">
                <pie-chart :chartdata="chartData" :key="unique"/>
              </div>
            </div>
          </sui-tab-pane>
          <sui-tab-pane title="Transactions" :attached="false">
            <div class="row">
              <div class="col-12">
                <loader v-show="loading_trx"/>
                <vuetable ref="vuetable_trx"
                          :api-url="url_trx"
                          :fields="fields_trx"
                          :per-page="10"
                          track-by="transaction_id"
                          :append-params="moreParams_trx"
                          :httpOptions="httpOptions"
                          data-path="data.data.data.data"
                          pagination-path="data.data.data"
                          @vuetable:pagination-data="onPaginationData_trx"
                          @vuetable:loading="onLoading_trx"
                          @vuetable:loaded="onLoaded_trx"
                >
                  <template slot="title" slot-scope="props">
                    <strong style="color: dodgerblue">{{ props.rowData.msisdn }}</strong><br>
                    <span style="font-size: smaller; color: gray">{{ props.rowData.first_name }}</span><br>
                  </template>
                  <template slot="amount" slot-scope="props">
                    <strong style="color: dodgerblue">{{ props.rowData.amount }}</strong><br>
                    <span style="font-size: smaller; color: gray">{{ props.rowData.transaction_type }}</span><br>
                  </template>
                  <template slot="source" slot-scope="props">
                    <strong>{{ props.rowData.source }}</strong><br>
                    <span style="font-size: smaller; color: gray">{{ props.rowData.narration }}</span><br>
                  </template>
                </vuetable>
                <div style="padding-top:10px">
                  <vuetable-pagination ref="pagination_trx" @vuetable-pagination:change-page="onChangePage_trx"></vuetable-pagination>
                </div>
              </div>
            </div>
          </sui-tab-pane>
          <sui-tab-pane title="Upload Contact" :attached="false">
            <div class="row">
              <div class="col-12">
                <sui-form>
                  <sui-form-field>
                    <label>Upload Contacts</label>
                    <input type="file" id="image" name="image" placeholder="Enter File" accept="text/csv" required>
                  </sui-form-field>
                  <div>
                    <strong>Instructions: </strong><br>
                    The file MUST be a <strong>CSV</strong> and in this specific format with these headers: <br>
                    <p style="color: red; font-size: 20px">mobile, full_names</p>
                    <a href="/assets/sample.csv" download>Download Sample</a>
                  </div>
                </sui-form>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <sui-button positive content="Upload" @click.prevent="uploadFile"></sui-button>
              </div>
            </div>
          </sui-tab-pane>
          <sui-tab-pane title="Settings" :attached="false">
            <transition name="fade">
              <sui-message
                  v-if="visible"
                  :content="message"
                  dismissable
                  @dismiss="handleDismiss"
                  negative
              />
            </transition>
            <sui-form>
              <sui-form-fields inline>
                <label>Do you want to activate rewards?</label>
                <sui-form-field>
                  <sui-checkbox radio v-model="incentive" name="rewards" label="YES" value="true" />
                </sui-form-field>
                <sui-form-field>
                  <sui-checkbox radio v-model="incentive" name="rewards" label="NO" value="false" />
                </sui-form-field>
              </sui-form-fields>
              <!--
                            <hr v-show="incentive === 'true'">
                            <sui-form-field v-show="incentive === 'true'">
                              <label>Upload Contacts</label>
                              <input type="file" id="image1" name="image" placeholder="Enter File" accept="text/csv" required>
                            </sui-form-field>
                            -->
              <hr v-show="incentive === 'true'">
              <sui-form-fields fields="four" v-show="incentive === 'true'">
                <sui-form-field>
                  <label>Reward</label>
                  <sui-dropdown
                      placeholder="Reward"
                      selection
                      v-model="reward_type_id"
                      :options="incentives"
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Incentive</label>
                  <sui-dropdown
                      placeholder="Incentive"
                      selection
                      v-model="incentive_type_id"
                      :options="incentives_types"
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Incentive Amount</label>
                  <input placeholder="Incentive Amount" type="number" v-model="amount"/>
                </sui-form-field>
                <sui-form-field>
                  <label>Total Budget (KES)</label>
                  <input placeholder="Total Budget" type="number" v-model="totalBudget" disabled/>
                </sui-form-field>
              </sui-form-fields>
            </sui-form>
            <hr>
            <sui-form>
              <sui-form-fields fields="three">
                <sui-form-field>
                  <label>Opt In Message</label>
                  <textarea rows="5" placeholder="Opt In Message" v-model="optin_message"></textarea>
                </sui-form-field>
                <sui-form-field>
                  <label>Opt Out Message</label>
                  <textarea rows="5" placeholder="Opt Out Message" v-model="optout_message"></textarea>
                </sui-form-field>
                <sui-form-field>
                  <label>Completed Message</label>
                  <textarea rows="5" placeholder="Opt Out Message" v-model="completed_message"></textarea>
                </sui-form-field>
              </sui-form-fields>
            </sui-form>
            <div class="row">
              <div class="col text-right">
                <sui-button content="Cancel" @click.prevent="setForm"></sui-button>
                <sui-button positive content="Save Changes" @click.prevent="saveSettings"></sui-button>
              </div>
            </div>
          </sui-tab-pane>
        </sui-tab>
      </div>
    </div>
    <!--  Modal | start survey -->
    <sui-modal v-model="modal" size="small">
      <sui-modal-header>Start Survey</sui-modal-header>
      <sui-modal-content>
        <transition name="fade">
          <sui-message
              v-if="visible"
              :content="message"
              dismissable
              @dismiss="handleDismiss"
              negative
          />
        </transition>
        <sui-modal-description class="mt-2">
          <sui-form>
            <sui-form-field>
              <label>Opt In Message</label>
              <textarea rows="4" placeholder="Opt In Message" v-model="optin_message"></textarea>
            </sui-form-field>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Opt Out Message</label>
                <textarea rows="3" placeholder="Opt Out Message" v-model="optout_message"></textarea>
              </sui-form-field>
              <sui-form-field>
                <label>Completed Message</label>
                <textarea rows="3" placeholder="Opt Out Message" v-model="completed_message"></textarea>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
          <hr v-show="uploadRes">
          <sui-form v-show="uploadRes">
            <sui-form-fields inline>
              <label>Do you want to activate rewards?</label>
              <sui-form-field>
                <sui-checkbox radio v-model="incentive" name="rewards" label="YES" value="true" />
              </sui-form-field>
              <sui-form-field>
                <sui-checkbox radio v-model="incentive" name="rewards" label="NO" value="false" />
              </sui-form-field>
            </sui-form-fields>
            <hr v-show="incentive === 'true'">
            <sui-form-fields fields="two" v-show="incentive === 'true'">
              <sui-form-field>
                <label>Reward</label>
                <sui-dropdown
                    placeholder="Reward"
                    selection
                    v-model="reward_type_id"
                    :options="incentives"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Incentive</label>
                <sui-dropdown
                    placeholder="Incentive"
                    selection
                    v-model="incentive_type_id"
                    :options="incentives_types"
                />
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="two" v-show="incentive === 'true'">
              <sui-form-field>
                <label>Incentive Amount</label>
                <input placeholder="Incentive Amount" type="number" v-model="amount"/>
              </sui-form-field>
              <sui-form-field>
                <label>Total Budget (KES)</label>
                <input placeholder="Total Budget" type="number" v-model="totalBudget" disabled/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button class="float-left" @click.native="setForm('reset')"> Cancel </sui-button>
        <sui-button positive @click.native="saveSettings('start')"> Start Survey</sui-button>
      </sui-modal-actions>
    </sui-modal>

    <!--  Modal | send sms -->
    <sui-modal v-model="sms" size="small">
      <sui-modal-header>Send SMS</sui-modal-header>
      <sui-modal-content>
        <transition name="fade">
          <sui-message
              v-if="visible"
              :content="message"
              dismissable
              @dismiss="handleDismiss"
              negative
          />
        </transition>
        <sui-modal-description class="mt-2">
          <sui-form>
            <sui-form-field>
              <label>Short Code</label>
              <sui-dropdown
                  placeholder="Short Code"
                  selection
                  v-model="shortCode"
                  :options="codes"
              />
            </sui-form-field>
            <sui-form-field>
              <label>Short Contact</label>
              <sui-dropdown
                  placeholder="Short Contact"
                  selection
                  v-model="blast_name"
                  :options="contacts"
              />
            </sui-form-field>
            <sui-form-field v-if="blast_name === 'quiz'">
              <label>Question</label>
              <sui-dropdown
                  placeholder="Question"
                  selection
                  v-model="question_id"
                  :options="quizes"
              />
            </sui-form-field>
            <sui-form-field>
              <label>Message to send</label>
              <div class="ui top attached segment">
                <div class="ui fluid">
                  <textarea id="text" v-model="text" name="w3review" rows="5" style="width: -webkit-fill-available; border: 0"></textarea>
                </div>
              </div>
              <div class="ui bottom attached secondary segment">
                <div class="ui equal width grid">
                  <div class="column"><strong><span v-text="wordCount"></span></strong> character(s)</div>
                  <div class="right aligned column"><strong><span v-text="pageCount"></span></strong> page(s)</div>
                </div>
              </div>
            </sui-form-field>
            <sui-form-fields fields="two">
              <sui-form-field>
                <sui-checkbox label="Schedule Message?" v-model="schedule" />
              </sui-form-field>
              <sui-form-field v-show="schedule">
                <label>Set Date/Time</label>
                <input type="datetime-local" v-model="time"/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button class="float-left" @click.native="toggleSms"> Cancel </sui-button>
        <sui-button primary @click.native="sendBlast(1)"> Query Recipients</sui-button>
        <sui-button positive @click.native="sendBlast(0)"> Send</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>

import CardStats from "@/components/card-stats";
import {mapActions, mapMutations} from "vuex";
import moment from "moment";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import VueJsonToCsv from 'vue-json-to-csv'
import Pie from "@/components/chartjs/pie-chart";
import PieChart from "@/components/chartjs/pie-chart";
import {random, split} from "lodash";

export default {
  components: {
    PieChart,
    Pie,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    VueJsonToCsv,
    CardStats
  },
  data(){
    return{
      modal: false,
      sms: false,
      incentive: 'false',
      visible: false,
      message: null,
      incentives: [],
      reward_type_id: 1,
      incentives_types: [],
      incentive_type_id: 1,
      amount: null,
      totalBudget: null,
      optin_message: null,
      optout_message: null,
      completed_message: null,
      total_recipients: null,
      app_name: null,
      active: false,
      question: {},
      currentPage: 1,
      last_page: null,
      current_page: null,

      /* vuetable */
      loading: true,
      fields: [
        {
          name: "msisdn",
          title: "Msisdn",
          titleClass: "",
          dataClass: "no-border",
          width: "130px"
        },
        {
          name: "first_name",
          title: "Name",
          titleClass: "",
          dataClass: "no-border",
          width: "130px"
        },
        {
          name: "response",
          title: "Response",
          titleClass: "",
          dataClass: "no-border",
        },
      ],
      moreParams: {
        start: "",
        end: "",
      },
      url: this.$store.state.api + 'survey/v1/responses/1',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 5,

      /* vuetable 1 */
      loading_trx: true,
      fields_trx: [
        {
          name: "transaction_id",
          title: "Transaction ID",
          sortField: "transaction_id",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "title",
          title: "Mobile",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "transaction_date",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          width: '120px',
          dataClass: "no-border"
        },
        {
          name: "source",
          title: "Source",
          titleClass: "",
          dataClass: "no-border",
          width: '180px'
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
      ],
      moreParams_trx: {
        start: "",
        end: "",
        s_setting_id: this.$store.state.survey_setting_Id,
      },
      url_trx: this.$store.state.api + 'admin/v1/transaction/system',

      prevRoute: null,
      index: 0,
      disabled: false,
      uploadRes: 1,

      q_data: [],
      r_data: [],
      stats: {},
      chartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      chartDataStats: {
        labels: ['Completed', 'Uncompleted'],
        datasets: [
          {
            backgroundColor: ['#E46651', '#00D8FF'],
            data: [40, 20]
          }
        ]
      },
      unique: 'key',
      uniqueStats: 'key',

      // send sms
      text: '',
      wordCount: 0,
      pageCount: 0,
      codes: [],
      contacts: [
        { text: 'All', value: 'all' },
        { text: 'Respondent', value: 'respondent' },
        { text: 'Non Respondent', value: 'non-respondent' },
        { text: 'Progressing', value: 'continue' },
        { text: 'Completed', value: 'complete' },
        { text: 'Per Question', value: 'quiz' }
      ],
      shortCode: null,
      blast_name: null,
      queryRecipient: 1,
      query: true,
      uniqueId: random(100000, 999999),
      schedule: null,
      question_id: null,
      time: '',
      quizes: [],
    }
  },

  mounted() {
    this.setRewardTypes()
    this.setIncentiveTypes()
    this.setForm()
    this.refresh()
    this.setStats()
    this.setCodes()
    this.setQuestions()
  },
  computed: {
    percent() {
      if (this.question.stats) {
        let completed = parseInt(this.question.stats.responses)
        let total = parseInt(this.question.stats.total_recipients)
        if (completed < 1) return 0;
        else return (completed / total) * 100
      }
      else {
        return 0;
      }
    },
    label() {
      return `${this.percent}% Complete Responses`
    }
  },
  watch:{
    amount(newVal, oldVal){
      if (newVal !== oldVal){
        this.totalBudget = parseInt(this.$store.state.contact_count) * parseFloat(this.amount)
        if (this.$store.state.balance < this.totalBudget){
          this.visible = true
          this.message = 'Current balance is less that the total budget. Please click the top up ' +
              'button at the top to recharge your account'
        }
      }
    },
    currentPage(newVal, oldVal){
      if (newVal !== oldVal){
        this.setQuiz()
      }
    },
    text: function (val,oldVal){
      if(val !== oldVal) {
        var count = val.length;
        // Display it as output
        this.wordCount = count;

        var page = count/160;
        this.pageCount = Math.ceil(page);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name
      if (vm.prevRoute === 'addQuiz'){
        vm.index = 2
        vm.disabled = true
      }
    })
  },
  methods: {
    ...mapActions(["getRewardTypes", "getIncentiveTypes", "updateSurveySettings", "getSurveySettings", "startStopSurvey",
      "addSurveyContact", "getRequest", "getSurveyStats", "downloadResponse", "getConfiguredChannels", "sendSms", "getQuestions"]),
    ...mapMutations(["setContactCount"]),
    async refresh() {
      await this.setQuiz()
      this.uniqueStats = random(1000, 9999)
    },
    /* vuetable */
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    /* vuetable 1 */
    reload_trx(){
      this.$refs.vuetable_trx.refresh();
    },
    onPaginationData_trx(paginationData) {
      this.$refs.pagination_trx.setPaginationData(paginationData);
    },
    onChangePage_trx(page) {
      this.$refs.vuetable_trx.changePage(page);
    },
    onLoading_trx() {
      this.loading_trx = true;
    },
    onLoaded_trx() {
      this.loading_trx = false;
    },

    async setRewardTypes() {
      let app = this
      let response = await this.getRewardTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives.push(list)
        })
      }
    },

    async setQuestions() {
      let app = this
      let response = await this.getQuestions({id: this.$store.state.survey_setting_Id})
      if (response.status === 200){
        response.message.data.forEach(function (item) {
          let list = { text: item.question, value: parseInt(item.id) }
          app.quizes.push(list)
        })
      }
    },

    async setStats() {
      let app = this
      let response = await this.getSurveyStats(this.$store.state.survey_id)
      if (response.status === 200){
        app.stats = response.message
        app.chartData.labels = response.message.graph.label
        app.chartData.datasets[0].data = response.message.graph.total
        app.chartData.datasets[0].backgroundColor = response.message.graph.color
        app.unique = random(1000, 99999).toString()
      }
    },

    async setQuiz() {
      let app = this
      let params = {
        per_page: 1,
        page: app.currentPage
      }
      let url = new URLSearchParams(params).toString();
      url = 'survey/v1/questions/' + this.$store.state.questionnaire.survey_setting_id + '?' + url
      let response = await this.getRequest(url)
      if (response.status === 200){
        console.log('question: ' + JSON.stringify(response.message))
        app.question = response.message.data[0]
        app.current_page = response.message.current_page
        app.last_page = response.message.last_page

        await app.rowClicked(app.question)
      }
    },

    async setDownload() {
      let app = this
      let params = {
        id: 1,
        export: 1
      }
      let response = await this.downloadResponse(params)
      if (response.status === 200){
        app.q_data = response.message.data
      }
    },

    async setIncentiveTypes() {
      let app = this
      let response = await this.getIncentiveTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives_types.push(list)
        })
      }
    },

    async saveSettings(action = null){
      let app = this

      app.handleDismiss()
      let payload = {
        id: this.$store.state.survey_setting_Id,
      }

      if (this.incentive === 'true'){
        if (app.amount < 1){
          return app.handleAlert('Amount must be greater than 0')
        }
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = app.amount
      }
      else {
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = 0
      }

      if (app.optin_message){
        payload.optin_message = app.optin_message
      }

      if (app.optout_message){
        payload.optout_message = app.optout_message
      }

      if (app.completed_message){
        payload.completed_message = app.completed_message
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this saves the changes!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateSurveySettings(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              if (action === 'start'){
                let payload = {
                  id: this.$store.state.survey_id,
                  action: 'start'
                }
                await app.startStopSurvey(payload)
              }
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.setForm()
                app.modal = false
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
      app.handleDismiss()
    },

    async stopSurvey(){
      let app = this

      app.handleDismiss()
      let payload = {
        id: this.$store.state.survey_id,
        action: 'stop'
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this stops this survey!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, stop!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.startStopSurvey(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Stopped!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.setForm()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
      app.handleDismiss()
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },
    async rowClicked(row){
      let id = await row.id
      console.log("got here: " + JSON.stringify(row))
      this.url = this.$store.state.api + 'survey/v1/responses/' + id
      this.reload()

      console.log("got here 1")
      this.chartDataStats.datasets[0].data = [
        parseInt(row.stats.responses),
        parseInt(row.stats.total_recipients) - parseInt(row.stats.responses)
      ]
      console.log("got here 2")
      let params = {
        id: id,
        export: 1
      }
      console.log("got here 3")
      let response = await this.downloadResponse(params)
      console.log("downloadResponse res: "+ id + JSON.stringify(response))
      if (response.status === 200){
        this.r_data = response.message.data
      }
      this.uniqueStats = random(1000, 9999)
      await this.setDownload()
    },
    async uploadFile() {
      let app = this

      if( document.getElementById("image").files.length === 0 ){
        return app.handleAlert('Contact file is missing')
      }

      const payload = new FormData();
      payload.append('id', this.$store.state.survey_setting_Id)
      payload.append('image', $('#image')[0].files[0])

      app.uploadRes = null

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this uploads this survey contact!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, upload!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addSurveyContact(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.uploadRes = result.value.status
              app.$swal.fire('Uploaded!', result.value.message, 'success')
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setForm(reset = '') {
      let app = this
      let settings = await this.getSurveySettings(this.$store.state.survey_setting_Id)
      settings = settings.message

      console.log('settings: ' + JSON.stringify(settings))

      if (parseFloat(settings.reward_value) > 0){
        app.incentive = 'true'
        app.amount = parseFloat(settings.reward_value)
        app.reward_type_id = parseInt(settings.reward_type_id)
        app.incentive_type_id = parseInt(settings.incentive_type_id)
        this.setContactCount(settings.total_recipients)
      }
      app.optin_message = settings.optin_message
      app.optout_message = settings.optout_message
      app.completed_message = settings.completed_message
      app.total_recipients = settings.total_recipients
      app.app_name = settings.app_name

      if (parseInt(settings.status) === 5){
        app.active = true
      }
      if (reset === 'reset') {
        app.modal = false
      }
    },

    async switchResponse(type){
      this.moreParams.non_respondents = type
      this.reload()
    },

    toggle() {
      this.modal = !this.modal
    },

    toggleSms() {
      this.sms = !this.sms
    },
    async sendBulkQuiz(id){
      this.blast_name = 'quiz'
      this.question_id = id
      this.sms = true
    },
    async setCodes() {
      let app = this
      let payload = { survey_channel_id: '' }
      let response = await this.getConfiguredChannels(payload)
      if (response.status === 200){
        //let data = JSON.parse(response.message.data[0].survey_channel_name)
        console.log('channels: ' + JSON.stringify(response.message))
        app.codes = []
        for (let i = 0; i < response.message.data.length; i++) {
          let data = split(response.message.data[i].survey_channel_name, ',');

          for (let j = 0; j < data.length; j++) {
            let list = {text: data[j].toString(), value: data[j]}
            app.codes.push(list)
          }
        }
      }
    },
    async sendBlast(query) {
      let app = this
      let isScheduled = '0'
      let res = 'Yes, send!'
      let res1 = 'Doing this sends this sms!'
      if (this.schedule){
        isScheduled = '1'
        if (!this.time){

          app.$swal.fire('Missing!', "Fill all the required fields", 'error')
          return;
        }
      }

      if (!this.shortCode || !this.text || !this.blast_name){

        app.$swal.fire('Missing!', "Fill all the required fields", 'error')
        return;
      }

      if (query === 1){
        this.queryRecipient = 1
        res = 'Yes, Query!'
        res1 = 'Doing this queries the recipients!'
      }
      else {
        this.queryRecipient = null
      }
      const payload = {
        shortCode: this.shortCode,
        message: this.text,
        queryRecipient: this.queryRecipient,
        uniqueId: this.uniqueId,
        isScheduled: isScheduled,
        time: this.time,
        blast_name: this.blast_name,
        question_id: this.question_id,
        s_setting_id: this.$store.state.survey_setting_Id,
      };

      app.$swal.fire({
        title: 'Are you sure?',
        text: res1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: res,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.sendSms(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire('Success!', result.value.message, 'success')
              if (!app.query){
                app.resetForm()
              }
              app.query = false
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    resetForm(){
      this.text = ''
      this.time = ''
      this.sms = false
    }
  }
};
</script>
<style media="screen" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.lh-30 {
  line-height: 30px;
}
.progress {
  height: 1.8em;
}
.ui.progress .bar>.progress {
  color: rgba(255, 255, 255, 0.7)!important;
  background-color: transparent!important;
}
.ui.progress:first-child {
  margin: 0 0 0.5em;
}
.ui.progress {
  margin: 1em 0 1.5em !important;
}
.item {
  color: rgba(0,0,0,.4);
  -webkit-transition: .1s color ease;
  transition: .1s color ease;
}
.list-shrink{
  padding-inline-start: inherit;
  margin-bottom: 0!important;
}
</style>
