<template>
  <div class="row">
    <div class="col">
      <div class="btn-group btn-block">
        <router-link :to="{ name: 'customers' }" :class="'btn btn-outline-dark ' + customers">Responses</router-link>
        <router-link :to="{ name: 'customer-trans' }" :class="'btn btn-outline-dark ' + trans">Transactions</router-link>
        <router-link :to="{ name: 'customer-reward' }" :class="'btn btn-outline-dark ' + reward">Rewards</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inpage-customer",
  props: {
    active: String // user | permission
  },
  data(){
    return {
      page: this.active,
      customers: '',
      trans: '',
      reward: '',
      bets: '',
      mpesa: '',

    }
  },
  methods: {
    renderActive(){
      let active = this.page
      if (active === 'customers') this.customers = 'active'
      if (active === 'trans') this.trans = 'active'
      if (active === 'reward') this.reward = 'active'
      if (active === 'bets') this.bets = 'active'
      if (active === 'mpesa') this.mpesa = 'active'
    }
  },
  mounted() {
    this.renderActive()
  }
}
</script>

<style scoped>

</style>
