<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">

      <!-- Card -->
      <div class="row d-flex align-items-center justify-content-center" style="height: 60em" v-if="newSurvey">
        <!-- Crad Body -->
        <div class="col text-center">
          <h1>Create a survey</h1>
          <p><strong>Get feedback from your customers <br> and and improve your services</strong></p>
          <router-link :to="{ name: 'wizard'}">
            <sui-button primary size="big">Create a Survey</sui-button>
          </router-link>
        </div>
      </div>
      <div class="row py-3 px-3" v-else>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <h3>All Surveys</h3>
            </div>
            <div class="col-6 text-right">
              <router-link :to="{ name: 'wizard'}">
                <div class="btn btn-success text-uppercase mb-2 mr-2">Create a Survey</div>
              </router-link>
            </div>
          </div>
        </div>
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-url="url"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="id"
                  :append-params="moreParams"
                  :httpOptions="httpOptions"
                  data-path="data.data.data"
                  pagination-path="data.data"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
                  @vuetable:row-clicked="rowClicked"
        >
          <template slot="title" slot-scope="props">
            <strong style="color: dodgerblue">{{ props.rowData.app_name }}</strong><br>
            <span style="font-size: smaller; color: gray">Created {{ moment(props.rowData.created_at).format('ll') }}</span>
          </template>
          <template slot="time" slot-scope="props">
            {{ moment(props.rowData.start_date).format('ll') }} - {{ moment(props.rowData.end_date).format('ll') }}
          </template>
          <template slot="analyze" slot-scope="props">
            <sui-icon style="cursor: pointer" size="large" name="eye" @click.prevent="view(props.rowData.id, props.rowData.settings_id)" />
          </template>
          <template slot="status" slot-scope="props">
            <sui-button v-if="parseInt(props.rowData.status) === 5" size="mini" color="green" content="Active" />
            <sui-button v-else-if="parseInt(props.rowData.status) === 3" size="mini" color="red" content="Deactivated" />
            <sui-button v-else size="mini" color="purple" content="Inactive" />
          </template>
          <template slot="actions" slot-scope="props">
            <sui-dropdown class="icon" icon="ellipsis horizontal" pointing="top right" direction="downward">
              <sui-dropdown-menu>
                <sui-dropdown-item @click.prevent="viewQ(parseInt(props.rowData.settings_id))">View Questionnaire</sui-dropdown-item>
              </sui-dropdown-menu>
            </sui-dropdown>
          </template>
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </div>
    </div>

    <!--  Modal | Survey -->
    <sui-modal v-model="open" size="small">
      <sui-modal-header>Create a survey</sui-modal-header>
      <sui-modal-content>
        <transition name="fade">
          <sui-message
              v-if="visible"
              :content="message"
              dismissable
              @dismiss="handleDismiss"
              negative
          />
        </transition>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Survey title</label>
              <input placeholder="Survey title" type="text" v-model="survey_name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Survey Description</label>
              <textarea rows="2" placeholder="Survey Description" v-model="description"></textarea>
            </sui-form-field>

            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Survey Period</label>
                <date-picker placeholder="Survey Period" style="width: 100%;" class="" v-model="time3" range @change="period"></date-picker>
              </sui-form-field>
              <sui-form-field>
                <label>Short Code</label>
                <sui-dropdown
                    placeholder="Short Code"
                    selection
                    v-model="selected_codes"
                    :options="codes"
                />
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button class="float-left" @click.native="toggle"> Cancel </sui-button>
        <sui-button positive @click.native="add"> Create Survey</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _, {split} from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";
import DatePicker from 'vue2-datepicker';
//import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker,
    //VueJsonToCsv
  },
  name: "clients",

  data() {
    return {
      open: false,
      visible: false,
      message: null,
      dateRange: {
        start: "",
        end: ""
      },
      purposes: [
        { text: 'Data Collect', value: 1 },
        { text: 'Product Review', value: 2 }
      ],
      codes: [
        { text: '764562', value: 764562 },
        { text: '678922', value: 678922 }
      ],
      selected_codes: null,
      incentives: [],
      selected_incentives: 1,
      incentives_types: [],
      selected_incentives_types: 1,
      questionnaires: [
        { text: 'New Questionnaire', value: 'none' },
        { text: 'First Questionnaire', value: 1 },
        { text: 'Second Questionnaire', value: 2 },
        { text: 'Third Questionnaire', value: 3 },
        { text: 'Fourth Questionnaire', value: 4 },
      ],
      selected_questionnaires: 'none',
      time3:"",
      amount: 50,
      survey_name: null,
      description: null,
      incentive: false,
      selected_purposes: null,
      client_id: parseInt(this.$store.state.client_id),

      /* vuetable */
      loading: true,
      fields: [
        {
          name: "title",
          title: "Title",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "app_code",
          title: "App Code",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "total_recipients",
          title: "Respondents",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
        {
          name: "count_responses",
          title: "Responses",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
        {
          name: "time",
          title: "Duration",
          titleClass: "center aligned no-border",
          dataClass: "no-border"
        },
        {
          name: "status",
          title: "Status",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
        {
          name: "analyze",
          title: "Analyze",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
        {
          name: "actions",
          title: "More",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
      ],
      moreParams: {
        start: "",
        end: "",
        client_id: this.$route.params.id
      },
      url: this.$store.state.api + 'survey/v1/view',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 10,
      newSurvey: false,
      moment: moment,
      data: [
        { name: 'Joe', surname: 'Roe' },
        { name: 'John', surname: 'Doe' }
      ]
    };
  },

  mounted() {
    this.setRewardTypes()
    this.setIncentiveTypes()
    this.setCodes()
  },

  methods: {
    ...mapActions(["fillSurvey", "getRewardTypes", "getIncentiveTypes", "getConfiguredChannels", "fillSurveySettingId",
      "getQuestionnaires", "fillQuestionnaire", "fillSurveyId", "downloadSurvey"]),
    /* vuetable */
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      console.log('paginationData: ' + paginationData.total)
      if (paginationData.total < 1){
        this.newSurvey = true;
      }
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    period(){
      this.dateRange.start = this.format_date(this.time3[0])
      this.dateRange.end = this.format_date(this.time3[1])
    },

    async setRewardTypes() {
      let app = this
      let response = await this.getRewardTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives.push(list)
        })
      }
    },

    async setIncentiveTypes() {
      let app = this
      let response = await this.getIncentiveTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives_types.push(list)
        })
      }
    },

    async setCodes() {
      let app = this
      let response = await this.getConfiguredChannels({ survey_channel_id: '' })
      if (response.status === 200){
        //let data = JSON.parse(response.message.data[0].survey_channel_name)
        let data = split(response.message.data[0].survey_channel_name, ',');
        app.codes = []
        for (let i = 0; i < data.length; i++) {
          let list = { text: data[i].toString(), value: data[i] }
          app.codes.push(list)
        }
      }
    },

    toggle() {
      this.open = !this.open
    },

    async add(){
      let app = this
      if (!this.survey_name || !app.selected_questionnaires || !app.selected_codes){
        return app.handleAlert('All mandatory fields are required')
      }
      let survey = {
        survey_name: app.survey_name,
        description: app.description,
        selected_questionnaires: app.selected_questionnaires,
        selected_purposes: app.selected_purposes,
        selected_codes: app.selected_codes,
        start_date: app.dateRange.start,
        end_date: app.dateRange.end,
      }
      if (this.incentive){
        if (!app.selected_incentives || !app.selected_incentives_types || parseFloat(app.amount) < 1){
          return app.handleAlert('All mandatory fields are required')
        }
        survey.selected_incentives = app.selected_incentives
        survey.selected_incentives_types = app.selected_incentives_types
        survey.amount = app.amount
      }
      if (app.selected_questionnaires !== 'none'){
        // do something
      }
      else {
        let saved = this.$store.state.survey
        saved.survey = survey
        await this.fillSurvey(saved)
        await this.$router.push({name: 'addQuiz'})
      }
    },

    async view(id, settings_id){
      this.onLoading()
      await Promise.all([
        this.fillSurveySettingId(settings_id),
        this.setQuestionnaire(settings_id),
        this.fillSurveyId(id)
      ])

      await this.$router.push({name: 'report'})
    },

    async viewQ(id){
      this.onLoading()
      await this.$router.push({name: 'questionnaire', params: { id: id}})
    },

    async rowClicked(row){
      let id = row.data.id
      let settings_id = row.data.settings_id
      //await this.fillSurvey(row.data)
      //await this.view(id, settings_id)
    },
    async setQuestionnaire(setting_id) {
      let params = {setting_id: setting_id}
      let res = await this.getQuestionnaires(params);

      await this.fillQuestionnaire(res.message.data[0])
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },

    async download(){
      let app = this
      let params = this.moreParams
      params.page = 1
      params.per_page = 100000
      let result = await this.downloadSurvey(params)
      if (result.status === 200) {
        app.$swal.fire('Downloaded!', result.message, 'success')
      } else {
        app.$swal.fire('Error!', result.value.message, 'error')
      }
    },
  }
};
</script>
<style media="screen" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style>

.download > button {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff !important;
  margin-bottom: 7px;
  background-color: #28a745 !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>
