<template>
  <div class="u-body">
    <div class="row py-3">
      <div class="col-6">
        <h3 class="mb-2">Add Client</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'client'}" class="btn text-white bg-success active" role="button" aria-pressed="true">Clients</router-link>
      </div>
    </div>
    <div class="add-users">
      <sui-form>
        <sui-form-fields fields="three">
          <sui-form-field>
            <label>Name</label>
            <input placeholder="Name" type="text" v-model="form.name"/>
          </sui-form-field>
          <sui-form-field>
            <label>Email</label>
            <input placeholder="Email Address" type="email" v-model="form.email"/>
          </sui-form-field>
          <sui-form-field>
            <label>Select Currency</label>
            <sui-dropdown
                placeholder="Currency"
                selection
                v-model="form.currency"
                :options="currencies"
            />
          </sui-form-field>
        </sui-form-fields>
        <hr>
        <sui-form-fields fields="six">
          <sui-form-field>
            <label>Inbound Service Fee</label>
            <input placeholder="Inbound Service" type="number" v-model="form.rate.inbound"/>
          </sui-form-field>
          <sui-form-field>
            <label>Outbound Service Fee</label>
            <input placeholder="Outbound Service" type="number" v-model="form.rate.outbound"/>
          </sui-form-field>
          <sui-form-field>
            <label>Response Processing Fee</label>
            <input placeholder="Response Processing Fee" type="number" v-model="form.rate.processing"/>
          </sui-form-field>
          <sui-form-field>
            <label>Reward Management Fee</label>
            <input placeholder="Reward Management Fee" type="number" v-model="form.rate.reward"/>
          </sui-form-field>
          <sui-form-field>
            <label>Blast Sms Fee</label>
            <input placeholder="Blast Sms Fee" type="number" v-model="form.rate.blast"/>
          </sui-form-field>
          <sui-form-field>
            <label>Survey Creation Fee</label>
            <input placeholder="Survey Creation Fee" type="number" v-model="form.rate.creation"/>
          </sui-form-field>
        </sui-form-fields>
        <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="createClient">Submit</button>
      </sui-form>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import { mapActions } from "vuex";
export default {
  data: function() {
    return {
      form: {
        currency: "KES",
        name: null,
        email: null,
        logo: null,
        rate: {
          inbound: 1.50,
          outbound: 1.00,
          processing: 1.50,
          reward: 0.10,
          blast: 0.80,
          creation: 2500.00,
        }
      },
      currencies: [
        { text: 'KES', value: 'KES' },
        { text: 'USD', value: 'USD' },
      ],
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["addClient"]),
    async createClient() {
      let app = this

      const payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new client!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addClient(payload)
        },
      })
          .then(async (result) => {
            //console.log("result: " + JSON.stringify(result))
            if (result.value.status === 201) {
              app.$swal.fire('Added!', result.value.message, 'success')
              await this.$router.push({name: 'client'})
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
  },
};
</script>
<style scoped>
</style>
