<template>
  <div class="card text-white" :style="{ backgroundColor: color }">
    <div class="card-body">
      <div class="media align-items-center py-2">
        <div class="media-body">
          <h5 class="h5 mb-2" style="font-size: 2em">{{ title }}</h5>
          <span class="h2 font-weight-normal mb-0" style="font-size: 5em">{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card-stats",
  props: {
    title: String,
    value: String,
    color: String
  }
}
</script>

<style scoped>

</style>
