<template>
  <div class="u-body">
    <div class="row pb-2">
      <div class="col-4">
        <h3>Transaction</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <date-picker placeholder="Filter by date" style="width: 100%;" class="" v-model="time3" range @change="dateFilter"></date-picker>
      </div>

      <div class="col-12 col-md-6">
        <nav aria-label="Page navigation example" class="float-left float-md-right">
          <ul class="pagination">
            <li class="page-item" :class="{ 'active': days === 0}">
              <a class="page-link" @click="searchRecords(0)" href="javascript:void(0)">Today</a>
            </li>
            <li class="page-item" :class="{ 'active': days === 3}">
              <a class="page-link" @click="searchRecords(3)" href="javascript:void(0)">3 Days</a>
            </li>
            <li class="page-item" :class="{ 'active': days === 7}">
              <a class="page-link" @click="searchRecords(7)" href="javascript:void(0)">7 Days</a>
            </li>
            <li class="page-item" :class="{ 'active': days === 30}">
              <a class="page-link" @click="searchRecords(30)" href="javascript:void(0)">30 Days</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-12">
        <loader v-show="loading"/>
        <vuetable ref="vuetable"
                  :api-url="url"
                  :fields="fields"
                  :per-page="perPage"
                  track-by="transaction_id"
                  :append-params="moreParams"
                  :httpOptions="httpOptions"
                  data-path="data.data.data.data"
                  pagination-path="data.data.data"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="onLoading"
                  @vuetable:loaded="onLoaded"
        >
          <template slot="title" slot-scope="props">
            <strong style="color: dodgerblue">{{ props.rowData.msisdn }}</strong><br>
            <span style="font-size: smaller; color: gray">{{ props.rowData.first_name }}</span><br>
          </template>
          <template slot="amount" slot-scope="props">
            <strong style="color: dodgerblue">{{ props.rowData.amount }}</strong><br>
            <span style="font-size: smaller; color: gray">{{ props.rowData.transaction_type }}</span><br>
          </template>
        </vuetable>
        <div style="padding-top:10px">
          <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker,
  },
  name: "approvals",

  data() {
    return {
      loading: true,
      fields: [
        {
          name: "transaction_id",
          title: "Transaction ID",
          titleClass: "",
          dataClass: "no-border",
          width: '50px'
        },
        {
          name: "title",
          title: "Mobile",
          titleClass: "",
          dataClass: "no-border",
          width: '80px'
        },
        {
          name: "transaction_date",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          width: '100px',
          dataClass: 'no-border'
        },
        {
          name: "source",
          title: "Source",
          sortField: "source",
          titleClass: "",
          dataClass: "no-border",
          width: '150px'
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        end: "",
        client_id: this.$route.params.id
      },
      url: this.$store.state.api + 'admin/v1/transaction/system',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      days:3,
      time3:"",
      perPage: 10,
      data: []
    };
  },

  mounted() {

    var vm = this;
  },

  methods: {
    ...mapActions([""]),
    dateFilter(){
      this.moreParams.start = this.format_date(this.time3[0])
      this.moreParams.end = this.format_date(this.time3[1])

      this.reload()
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },
    searchRecords(val){
      this.moreParams.start = moment().subtract(val, "days").format("YYYY-MM-DD")
      this.moreParams.end = moment().format("YYYY-MM-DD")

      this.reload()
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },
  }
};
</script>
<style>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
/*

table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0.8rem;
}
*/

</style>
