<template>
  <div>
    <div class="u-body">
      <transition name="fade">
        <sui-message
            v-if="visible"
            :content="message"
            dismissable
            @dismiss="handleDismiss"
            negative
        />
      </transition>
      <div class="row py-3">
        <div class="col">
          <h3>{{ questionaire_name }}</h3>
        </div>
        <div class="col text-right">
          <router-link :to="{ name: 'questionnaire' }" class="btn btn-warning text-uppercase mb-2 mr-2">Back</router-link>
        </div>
      </div>
      <!-- Card -->
      <div class="mb-5">
        <!-- Crad Body -->
        <div class="card-body">
          <h4>No of Questions {{ number_of_questions }}</h4>
          <sui-segment v-for="(payload, index) in questions" v-bind:key="index">
            <div class="row">
              <div class="col-1 text-center">
                <sui-button primary size="mini">Q {{ index + 1 }}</sui-button>
<!--                <p>{{ sections[selected_section][index].question }} || index {{ index }}</p>-->
              </div>
              <div class="col-11">
                <sui-form>
                  <sui-form-field>
                    <textarea rows="2" placeholder="Type your question here" v-model="payload.question"></textarea>
                  </sui-form-field>
                </sui-form>
                <div class="row pt-5">
                  <div class="col-md-3">
                    <sui-form>
                      <sui-form-field>
                        <sui-dropdown
                            placeholder="Type of question"
                            selection
                            v-model="payload.survey_question_type_id"
                            :options="quiz_types"
                            disabled
                        />
                      </sui-form-field>
                    </sui-form>
                  </div>
                  <div class="col-md-9">
                    <!-- 1. Open Ended -->
                    <sui-form v-if="parseInt(payload.survey_question_type_id) === 1">
                      <sui-form-fields fields="two">
                        <sui-form-field>
                          <input placeholder="User will enter text" type="text" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.options[0].link_question_id"
                              :options="links"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                    </sui-form>
                    <!-- 2. Net Promoted Score(NPS) -->
                    <sui-form v-else-if="parseInt(payload.survey_question_type_id) === 2">
                      <div class="row py-1" v-for="(item, counter) in payload.rating" v-bind:key="counter">
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Min" type="number" v-model="item.min"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Max" type="number" v-model="item.max"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <sui-dropdown
                                placeholder="Link To"
                                selection
                                v-model="item.link_question_id"
                                :options="links"
                            />
                          </sui-form-field>
                        </div>

                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Min" type="number" v-model="item.min"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Max" type="number" v-model="item.max"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <sui-dropdown
                                placeholder="Link To"
                                selection
                                v-model="item.link_question_id"
                                :options="links"
                            />
                          </sui-form-field>
                        </div>
                      </div>
                    </sui-form>
                    <!-- 3. Yes/No -->
                    <sui-form v-else-if="parseInt(payload.survey_question_type_id) === 3">
                      <sui-form-fields fields="two">
                        <sui-form-field>
                          <input placeholder="Yes" type="text" value="Yes" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.link_question_id"
                              :options="links"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                      <sui-form-fields fields="two">
                        <sui-form-field>
                          <input placeholder="No" type="text" value="No" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.link_question_id"
                              :options="links"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                    </sui-form>
                    <!-- 7. Single Selection || 4. Multiple Selection -->
                    <sui-form v-else-if="parseInt(payload.survey_question_type_id) === 7 || parseInt(payload.survey_question_type_id) === 4">
                      <div class="row py-1" v-for="(item, counter) in payload.options" v-bind:key="counter">
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Option" type="text" v-model="item.question_option"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <sui-dropdown
                                placeholder="Link To"
                                selection
                                v-model="item.link_question_id"
                                :options="links"
                            />
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Option" type="text" v-model="item.question_option"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <sui-dropdown
                                placeholder="Link To"
                                selection
                                v-model="item.link_question_id"
                                :options="links"
                            />
                          </sui-form-field>
                        </div>
                      </div>
                    </sui-form>
                  </div>
                </div>
              </div>
            </div>
          </sui-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sui-button class="text-center" content="Save Changes" primary fluid @click.prevent="submit"></sui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions, mapMutations} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker
  },
  name: "clients",

  data() {
    return {
      loading: true,
      schedule: false,
      visible: false,
      update: false,
      message: null,
      purposes: [
        { text: 'Data Collect', value: 1 },
        { text: 'Product Review', value: 2 }
      ],
      codes: [
        { text: '764562', value: 1 },
        { text: '678922', value: 2 }
      ],
      types: [
        { text: 'Linked', value: 4 },
      ],
      selected_types: 4,
      quiz_types: [],
      time3:"",
      level: 1,

      questionaire_name: '',
      number_of_questions: null,
      questions: [],
      links: [
        { text: 'Next Quiz', value: 1}
      ],

      sections: [
        [
          {
            question: null,
            question_type_id: null,
            unique_id: null,
            link_to: 1,
            options: [{ option: null, link_to: 1 }],
            yes: { link_to: 1 },
            no: { link_to: 1 },
            rating: [{ min: 1, max: 10, link_to: 1 }]
          },
        ]
      ],
      selected_section: 0,
      selected_links: 1,
    };
  },

  mounted() {
    this.setQuizTypes()
    this.setForm()
  },

  methods: {
    ...mapActions(["fillSurvey", "updateQuestionnaire", "getQuizTypes", "addSurveyContact", "fillSurveySettingId",
      "getQuestionnaires", "fillQuestionnaire", "fillSurveyId"]),

    setForm(){
      let data = this.$store.state.data
      let app = this
      app.questionaire_name = data.questionaire_name
      app.number_of_questions = data.number_of_questions
      app.questions = data.questions
      // generate links
      app.generateLinks()
    },
    // generate links quizs
    async generateLinks(){
      let app = this
      app.links = [{ text: 'Next Quiz', value: 1}]
      app.questions.forEach(function (item) {
        let list = { text: item.question, value: parseInt(item.unique_id) }
        app.links.push(list)
      })
    },

    // options
    addOptions(index){
      this.questions[index].options.push({option: null, link_to: 1})
    },
    deleteOption(index, counter){
      this.questions[index].options.splice(counter,1);
    },

    // rating
    addRating(index){
      this.questions[index].rating.push({ min: 1, max: 10, link_to: 1 })
    },
    deleteRating(index, counter){
      this.questions[index].rating.splice(counter,1);
    },

    // question
    addQuiz(){
      let length = this.questions.length + 1
      let unique_id = this.links[length].value
      this.questions.push(
          {
            question: null,
            unique_id: unique_id,
            question_type_id: null,
            link_to: 1,
            options: [{ option: null, link_to: 1 }],
            yes: { link_to: 1 },
            no: { link_to: 1 },
            rating: [{ min: 1, max: 10, link_to: 1 }]
          }
      )
    },
    deleteQuiz(counter){
      this.questions.splice(counter,1);
    },

    async setQuizTypes() {
      let app = this
      let response = await this.getQuizTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.quiz_types.push(list)
        })
      }
    },

    // generate sections from level entered
    async generateSections(){
      let app = this
      for (let i = 1; i < this.level; i++) {
        // sections
        let new_section = [{
          question: null,
          question_type_id: null,
          unique_id: null,
          link_to: null,
          options: [{ option: 'option', link_to: null }],
          yes: { link_to: null },
          no: { link_to: null },
          rating: [{ min: 1, max: 10, link_to: null }]
        }]
        app.sections.push(new_section)
      }
    },

    async generate(){
      await Promise.all([this.generateLinks(), this.generateSections()])
      this.toggleQuestion()
    },
    async add(){
      let app = this
      app.handleDismiss()
      if (!this.selected_types || !app.questionaire_name){
        return app.handleAlert('All mandatory fields are required')
      }
      if (app.selected_types === 4 && !app.level){
        return app.handleAlert('All mandatory fields are required')
      }
      let survey = {
        selected_types: app.selected_types,
        questionaire_name: app.questionaire_name,
        level: parseInt(app.level),
      }

      let saved = this.$store.state.survey
      saved.questionnaire = survey
      await Promise.all([this.fillSurvey(saved), this.generateLinks()])
      this.toggleQuestion()
    },

    toggleQuestion(update = null) {
      this.handleDismiss()
      if (update) this.update = true
      this.question = !this.question
    },

    async submit(){
      let app = this

      app.handleDismiss()
      // verify each quiz
      let errors = 0
      for (let i = 0; i < app.questions.length; i++) {
        let item = app.questions[i]
        if (!item.question){
          errors++
        }
        if (item.survey_question_type_id !== 1){
          for (let j = 0; j < item.options.length; j++) {
            let y = item.options[j]
            if (!y.question_option){
              errors++
            }
          }
        }
      }
      if (errors > 0){
        return app.handleAlert('Some of the questions contain empty fields. Fill all the questions before submitting')
      }

      let payload = { questions: app.questions }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates questionnaire!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          console.log('payload 22: ' + JSON.stringify(payload))
          return await this.updateQuestionnaire(payload)

        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                app.resetForm()

                // redirect to settings
                //await this.$router.push({name: 'report'})
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },
    resetForm(){
      //this.fillSurvey({})
    }
  }
};
</script>
