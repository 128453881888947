<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <div class="row pb-3 pt-5">
        <div class="col-6">
          <h3>All Clients</h3>
        </div>
        <div class="col-6 text-right">
          <router-link :to="{ name: 'addClient' }" class="btn btn-success text-uppercase mb-2 mr-2">Add Client</router-link>
        </div>
      </div>

      <!-- Card -->
      <div class="card mb-5">
        <!-- Crad Body -->
        <div class="card-body pt-0">
          <!-- Table -->
          <div class="">
            <div class="row ml-3">
              <loader v-show="loading"/>
              <vuetable ref="vuetable"
                        :api-url="url"
                        :fields="fields"
                        :per-page="perPage"
                        track-by="id"
                        :append-params="moreParams"
                        :httpOptions="httpOptions"
                        data-path="data.data.data"
                        pagination-path="data.data"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
              >
                <template slot="status" slot-scope="props">
                  <sui-button v-if="parseInt(props.rowData.status) === 1" size="mini" color="green" content="Active" />
                  <sui-button v-else-if="parseInt(props.rowData.status) === 3" size="mini" color="red" content="Deactivated" />
                  <sui-button v-else size="mini" color="purple" content="Inactive" />
                </template>
                <template slot="actions" slot-scope="props">
                  <sui-dropdown class="icon" icon="ellipsis horizontal" pointing="top right" direction="downward">
                    <sui-dropdown-menu>
                      <sui-dropdown-item v-if="parseInt(props.rowData.status) === 1" @click.prevent="editClient(props.rowData.id, 3)">Deactivate</sui-dropdown-item>
                      <sui-dropdown-item v-else @click.prevent="editClient(props.rowData.id, 1)">Activate</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="editRow(props.rowData)">Edit</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="viewS(props.rowData.id)">View Survey</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="viewTrx(props.rowData.id)">View Transactions</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="viewU(props.rowData.id)">View Users</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="ratecard(props.rowData.rates)">Rate Card</sui-dropdown-item>
                      <sui-dropdown-item @click.prevent="setClientChannels(props.rowData.id)">Channels</sui-dropdown-item>
                    </sui-dropdown-menu>
                  </sui-dropdown>
                </template>
              </vuetable>
              <div style="padding-top:10px">
                <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Modal | Rate Card -->
    <sui-modal v-model="open" size="tiny">
      <sui-modal-header>Rate Card</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <div class="row">
            <div class="col-12">
              <sui-table striped celled>
                <sui-table-header>
                  <sui-table-row>
                    <sui-table-header-cell>Service</sui-table-header-cell>
                    <sui-table-header-cell>Amount</sui-table-header-cell>
                  </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                  <sui-table-row v-for="rate in rates" :key="rate.service">
                    <sui-table-cell>{{ rate.service }}</sui-table-cell>
                    <sui-table-cell>{{ rate.currency }} {{ parseFloat(rate.rate).toFixed(2) }}</sui-table-cell>
                  </sui-table-row>
                </sui-table-body>
              </sui-table>
            </div>
          </div>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="toggle"> Cancel </sui-button>
      </sui-modal-actions>
    </sui-modal>

    <!--  Modal | Channels -->
    <sui-modal v-model="channel" size="tiny">
      <sui-modal-header>Channels</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <div class="row">
            <div class="col-12">
              <sui-table striped celled>
                <sui-table-header>
                  <sui-table-row>
                    <sui-table-header-cell>Method</sui-table-header-cell>
                    <sui-table-header-cell>Channels</sui-table-header-cell>
                  </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                  <sui-table-row v-for="item in channels" :key="item.id">
                    <sui-table-cell>{{ item.name }}</sui-table-cell>
                    <sui-table-cell>{{ item.survey_channel_name }}</sui-table-cell>
                  </sui-table-row>
                </sui-table-body>
              </sui-table>
            </div>
            <div class="col-12" v-if="newChannel">
              <sui-divider />
              <sui-form>
                <sui-form-fields fields="two">
                  <sui-form-field>
                    <label>Select Channel</label>
                    <sui-dropdown
                        placeholder="Channel"
                        selection
                        v-model="survey_channel_id"
                        :options="survey_channels"
                    />
                  </sui-form-field>
                  <sui-form-field>
                    <label>Channel Name</label>
                    <input placeholder="LIDENCO" type="text" v-model="survey_channel_name"/>
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>
            </div>
          </div>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button primary v-if="newChannel" @click="addNewChannel"> Save </sui-button>
        <sui-button primary v-else @click="toggleNewChannel">Add New </sui-button>
        <sui-button @click.native="toggleChannel" class="float-left"> Close </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "clients",

  data() {
    return {
      open: false,
      channel: false,
      newChannel: false,
      rates: [],
      channels: [],

      /* vuetable */
      loading: true,
      fields: [
        {
          name: "name",
          title: "Name",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "email",
          title: "Email",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "code",
          title: "Code",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "balance",
          title: "Balance",
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "status",
          title: "Status",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
        {
          name: "created_at",
          title: "Created",
          formatter (value) {
            return moment(value).format('lll');
          },
          dataClass: "no-border"
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        },
      ],
      moreParams: {
        start: "",
        end: "",
      },
      url: this.$store.state.api + 'client/v1/view',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 10,
      newSurvey: false,
      moment: moment,

      survey_channels: [],
      survey_channel_name: '',
      survey_channel_id: null,
      client_id: null,
    };
  },

  mounted() {
    this.setChannels()
  },

  methods: {
    ...mapActions(["getSystemUsers", "fillClient", "scheduleCs", "resendOTP", "updateUser", "updateClient",
      "getClientChannels", "addClientChannel", "getChannels"]),
    /* vuetable */
    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },

    toggle() {
      this.open = !this.open
    },

    toggleChannel() {
      this.channel = !this.channel
    },

    toggleNewChannel() {
      this.newChannel = !this.newChannel
    },

    async ratecard(list) {
      this.rates = list
      this.open = true
    },

    async editRow(data) {
      await this.fillClient(data)
      await this.$router.push({name: 'editClient'})
    },

    async addUser(client_id) {
      await this.fillClientId(client_id)
      await this.$router.push({name: 'addClientUser'})
    },

    async otp(profileId){
      // Use sweetalert2
      let app = this
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this resends OTP to this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.resendOTP(profileId)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Reset!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.reload()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async activate(profileId) {
      let app = this

      const payload = { status: 1, id: profileId }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this activates this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, activate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Activated!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async addNewChannel() {
      let app = this

      const payload = {
        client_id: app.client_id,
        survey_channel_name: app.survey_channel_name,
        survey_channel_id: app.survey_channel_id
      }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this a new channel to this client!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addClientChannel(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 201) {
              app.newChannel = false
              app.$swal.fire('Added!', result.value.message, 'success')
              await app.resetForm()
              await app.setClientChannels(payload.client_id)
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async deactivate(profileId) {
      let app = this

      const payload = { status: 3, id: profileId }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this deactivates this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deactivate!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Deactivated!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async editClient(id, status) {
      let app = this

      const payload = { id: id, status: status }

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates this client!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateClient(payload)
        },
      })
          .then(async (result) => {
            //console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Updated!', result.value.message, 'success')
              await this.$router.push({name: 'client'})
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async viewS(id){
      this.onLoading()
      await this.$router.push({name: 'survey', params: { id: id}})
    },

    async viewTrx(id){
      this.onLoading()
      await this.$router.push({name: 'transactions', params: { id: id}})
    },

    async viewU(id){
      this.onLoading()
      await this.$router.push({name: 'user', params: { id: id}})
    },

    async setClientChannels(client_id) {
      let app = this
      app.client_id = client_id
      let url = '?client_id=' + client_id
      this.channel = true
      let response = await this.getClientChannels(url)
      if (response.status === 200){
        app.channels = response.message.data
      }
    },

    async setChannels() {
      let app = this
      let response = await this.getChannels();
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.survey_channels.push(list)
        })
      }
    },

    async resetForm(){
      this.form.start_time = null
      this.form.stop_time = null
      this.open = false
      this.schedule = false
      this.newChannel = false
      this.client_id = null
      this.survey_channel_id = null
      this.survey_channel_name = null
    },
  }
};
</script>
