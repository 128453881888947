<template>
  <div
      :class="type === 'primary' ? 'alert-primary' : (type === 'success' ? 'alert-success' : ( type === 'warning' ? 'alert-warning' : (type === 'danger' ? 'alert-danger' : 'alert-info')))"
      class="alert fade show"
      role="alert"
  >
    <span class="alert-icon ti-comments mr-3"></span>
    {{ message }}
    <button class="close" type="button" aria-label="Close"
            data-dismiss="alert">
      <span class="ti-close" aria-hidden="true"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "alert",
  props: {
    message: String,
    type: String
  }
}
</script>

<style scoped>

</style>
