<template>
  <div>
  <div class="u-body">
    <h3 class="mb-2">Customers</h3>

    <customer-profile />

    <div class="row py-3" v-show="customer">
      <div class="col-12">
        <inpage-customer active="reward" />
      </div>
    </div>

    <div class="card mb-5 w-100" v-show="customer">
      <!-- Crad Body -->
      <div class="card-body px-0 pt-0">
        <!-- Table -->
        <div class="table-responsive">
          <div class="row ml-3">
            <loader v-show="loading"/>
            <vuetable ref="vuetable"
                      :api-url="url"
                      :fields="fields"
                      :per-page="perPage"
                      track-by="id"
                      :append-params="moreParams"
                      :httpOptions="httpOptions"
                      data-path="data.data.data"
                      pagination-path="data.data"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
            >
            </vuetable>
            <div style="padding-top:10px">
              <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import moment from "moment";
import CustomerProfile from "@/components/customer-profile";
import InpageCustomer from "@/components/tabs/customer-search";

export default {
  components: {
    InpageCustomer,
    CustomerProfile,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "approvals",

  data() {
    return {
      loading: false,
      customer: false,
      fields: [
        {
          name: "reference_type",
          title: "Reference",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
        {
          name: "transaction_type",
          title: "Transaction Type",
          titleClass: "",
          dataClass: "no-border",
          width: '130px'
        },
        {
          name: "amount",
          title: "Amount",
          sortField: "amount",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
        {
          name: "source",
          title: "Source",
          titleClass: "",
          dataClass: "no-border",
          width: '100px'
        },
        {
          name: "description",
          title: "Description",
          titleClass: "",
          dataClass: "no-border",
          width: '180px'
        },
        {
          name: "created_at",
          title: "Date",
          formatter (value) {
            return moment(value).format('lll');
          },
          width: '120px',
          dataClass: 'no-border'
        },
      ],
      moreParams: {},
      url: this.$store.state.api + 'admin/v1/search/reward',
      httpOptions: {
        headers: {
          'X-Requested-With': "XMLHttpRequest",
          'X-App-Key': this.$store.getters.StateAppKey,
          'X-Authorization-Key': this.$store.getters.StateAuthKey,
          'X-Token-Key': this.$store.getters.StateTokenKey,
        }
      },
      perPage: 5,
    };
  },
  computed: {
    msisdn () {
      return this.$store.state.msisdn
    }
  },

  methods: {
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.reload()
    },
    createdAt: function(value) {
      console.log("date: " + value)
      if (!value) {
        return "-";
      } else {
        return moment(value).format('lll');
      }
    },

    reload(){
      this.moreParams.msisdn = this.msisdn
      this.$refs.vuetable.refresh();
      this.customer = true
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded() {
      this.loading = false;
    },
  }
};
</script>
<style scoped>
td.vuetable-slot {
  overflow: visible !important;
}
.l-container-content{
  padding: 1.5em;
}
</style>
