<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">

      <div class="row py-3 px-3">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <h3>Rate card Settings</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-sm-6 col-md-4 col-xl-4 mb-5" v-for="rate in rates" :key="rate.service">
          <card-stats :title="rate.name + ' (' +rate.currency +')'" :value="parseFloat(rate.rate).toFixed(2)" :color="rate.color"></card-stats>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";
import CardStats from "@/components/card-stats";

export default {
  components: { CardStats },
  name: "clients",

  data() {
    return {
      open: false,
      rates: [],
      color: ['#f1be25','#FF4E50','#1a203b','#f12559','#28a745']
    };
  },

  mounted() {
    this.setRates()
  },

  methods: {
    ...mapActions(["getRateCards"]),
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },

    async setRates() {
      let app = this
      let response = await this.getRateCards()
      if (response.status === 200){
        app.rates = response.message
        for (let i = 0; i < app.rates.length; i++) {
          app.rates[i].color = app.color[i]
        }
      }
    },
  }
};
</script>
<style media="screen" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
