import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index";
import Login from '../views/login.vue'
import SignUp from '../views/signup.vue'
import ForgotPass from '../views/forgot-pass.vue'
import index from '../components'


import Dashboard from "../pages/dashboard";

import Customers from "../pages/customers/index";
import CustomerMpesa from "../pages/customers/mpesa";
import CustomerTrans from "../pages/customers/trans";
import CustomerReward from "../pages/customers/reward";

import Roles from "../pages/system-roles/index";
import Permissions from "../pages/system-permissions/index";
import Configs from "../pages/system-configs/index";
import RateCard from "../pages/system-configs/ratecard";

// Survey
import Survey from "../pages/surveys/index";
import Questionnaire from "../pages/questionnaires/index";
import addQuiz from "../pages/surveys/addQuiz";
import wizard from "../pages/surveys/wizard";
import editQuiz from "../pages/surveys/editQuiz";
import Reports from "../pages/reports/index";

// User
import User from "../pages/system-users/index";
import userLogs from "../pages/system-users/logs";
import addUser from "../pages/system-users/addUser";
import editUser from "../pages/system-users/editUser";

// Clients
import Client from "../pages/clients/index";
import addClient from "../pages/clients/addUser";
import editClient from "../pages/clients/editUser";

// transaction
import Trans from "../pages/transactions/index"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/signup', name: 'signup', component: SignUp, meta: { guest: true } },
        { path: '/forgot-password', name: 'forgot', component: ForgotPass, meta: { guest: true } },
        { path: '/', name: 'login', component: Login, meta: { guest: true }
        },
        {
            path: '/app',
            component: index,
            children: [
                { path: '', name: 'dashboard', component: Dashboard, meta: { permission: 'Read Statistics', requiresAuth: true }},

                { path: 'customers', name: 'customers', component: Customers, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/mpesa', name: 'customer-mpesa', component: CustomerMpesa, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/trans', name: 'customer-trans', component: CustomerTrans, meta: { permission: 'Customers', requiresAuth: true }},
                { path: 'customers/reward', name: 'customer-reward', component: CustomerReward, meta: { permission: 'Customers', requiresAuth: true }},

                { path: 'roles', name: 'roles', component: Roles, meta: { permission: 'Read System Roles', requiresAuth: true }},
                { path: 'permissions', name: 'permissions', component: Permissions, meta: { permission: 'Permissions', requiresAuth: true }},
                { path: 'configs', name: 'configs', component: Configs, meta: { permission: 'Read System Configurations', requiresAuth: true }},
                { path: 'ratecard', name: 'ratecard', component: RateCard, meta: { permission: 'Read Ratecard', requiresAuth: true }},

                { path: 'user/:id(\\d+)?', name: 'user', component: User, meta: { permission: 'Read Admin Users', requiresAuth: true }},
                { path: 'user/add', name: 'addUser', component: addUser, meta: { permission: 'Add User', requiresAuth: true }},
                { path: 'user/edit', name: 'editUser', component: editUser, meta: { permission: 'Edit User', requiresAuth: true }},
                { path: 'user/logs', name: 'userLogs', component: userLogs, meta: { permission: 'User Logs', requiresAuth: true }},

                { path: 'client', name: 'client', component: Client, meta: { permission: 'Read Client', requiresAuth: true }},
                { path: 'client/add', name: 'addClient', component: addClient, meta: { permission: 'Add User', requiresAuth: true }},
                { path: 'client/edit', name: 'editClient', component: editClient, meta: { permission: 'Edit User', requiresAuth: true }},

                { path: 'survey/:id(\\d+)?', name: 'survey', component: Survey, meta: { permission: 'Read Surveys', requiresAuth: true }},
                { path: 'questionnaire/:id(\\d+)?', name: 'questionnaire', component: Questionnaire, meta: { permission: 'Read Surveys', requiresAuth: true }},
                { path: 'quiz/add', name: 'addQuiz', component: addQuiz, meta: { permission: 'Read Surveys', requiresAuth: true }},
                { path: 'survey/wizard', name: 'wizard', component: wizard, meta: { permission: 'Survey Wizard', requiresAuth: true }},
                { path: 'quiz/edit', name: 'editQuiz', component: editQuiz, meta: { permission: 'Read Surveys', requiresAuth: true }},
                { path: 'report', name: 'report', component: Reports, meta: { permission: 'Read Surveys', requiresAuth: true }},

                { path: 'transactions/:id(\\d+)?', name: 'transactions', component: Trans, meta: { permission: 'Transactions', requiresAuth: true }},
            ]
        },
    ]
})


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/app/");
            return;
        }
        next();
    } else {
        next();
    }
})


export default router
