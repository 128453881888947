<template>
  <div>
  <!-- Tabs -->
  <div class="u-body">
    <inpage-navigation active="permissions"></inpage-navigation>
    <div class="row pb-3 pt-5">
      <div class="col-6">
        <h3>System Permissions</h3>
      </div>
    </div>

    <!-- Card -->
    <div class="row">
      <!-- Crad Body -->
      <div class="col-12">
        <!-- Table -->
        <div class="table-responsive">
          <div class="">
            <loader v-show="loading"/>
            <vuetable ref="vuetable"
                      :api-mode="false"
                      :fields="fields"
                      :per-page="perPage"
                      track-by="id"
                      :data-manager="dataManager"
                      pagination-path="pagination"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
            >
            </vuetable>
            <div style="padding-top:10px">
              <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";

export default {
  components: {
    Vuetable,
    InpageNavigation,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "roles",

  data() {
    return {
      loading: true,
      open: false,
      fields: [
        {
          name: "name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Permission Name',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "description",
          title: '<span class="orange glyphicon glyphicon-user"></span> Permission Description',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "created_at",
          title: "Date Created",
          formatter (value) { return moment(value).format('lll') },
          dataClass: 'no-border'
        },
      ],
      sortOrder: [
        {
          field: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
    };
  },

  mounted() {

    var vm = this;

    vm.setPermissions()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getSystemPermissions"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setPermissions() {
      let response = await this.getSystemPermissions()
      this.data = response.message
    },
  }
};
</script>
