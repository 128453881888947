<template>
  <div class="u-body" v-if="progress">
    <div class="row d-flex align-items-center justify-content-center" style="height: 60em">
      <!-- Crad Body -->
      <div class="col text-center">
        <h1>In Progress</h1>
      </div>
    </div>
  </div>
  <div class="u-body" v-else>
    <div class="access-page">
      <div class="row">
        <div class="col-12 col-md-6">
          <date-picker :open="open" placeholder="Filter by date" style="width: 100%; display: none" class="" v-model="time3" range @change="dateFilter"></date-picker>
        </div>

        <div class="col-12 col-md-6">
          <nav aria-label="Page navigation example" class="float-left float-md-right">
            <ul class="pagination">
              <li class="page-item" :class="{ 'active': days === 0}">
                <a class="page-link" @click="searchRecords(0)" href="javascript:void(0)">Today</a>
              </li>
              <li class="page-item" :class="{ 'active': days === -1}">
                <a class="page-link" @click="searchRecords(-1)" href="javascript:void(0)">Yesterday</a>
              </li>
              <li class="page-item" :class="{ 'active': days === 3}">
                <a class="page-link" @click="searchRecords(3)" href="javascript:void(0)">3 Days</a>
              </li>
              <li class="page-item" :class="{ 'active': days === 7}">
                <a class="page-link" @click="searchRecords(7)" href="javascript:void(0)">7 Days</a>
              </li>
              <li class="page-item" :class="{ 'active': days === 30}">
                <a class="page-link" @click="searchRecords(30)" href="javascript:void(0)">30 Days</a>
              </li>
              <li class="page-item" :class="{ 'active': days === 'custom'}">
                <a class="page-link" @click="toggle" href="javascript:void(0)">Custom</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col mb-5">
        <card-stats title="Customers" :value="profiles.toString()" color="#f1be25"></card-stats>
      </div>
      <div class="col mb-5">
        <card-stats title="Transactions" :value="accounting.format(transaction, 2)" color="#1a203b"></card-stats>
      </div>

      <div class="col mb-5">
        <card-stats title="SMS sent" :value="sms.toString()" color="#f12559"></card-stats>
      </div>

      <div class="col mb-5">
        <card-stats title="Surveys" :value="survey.toString()" color="#28a745"></card-stats>
      </div>
      <div v-show="parseInt(this.$store.state.client_id) === 0" class="col mb-5">
        <card-stats title="P&L" :value="accounting.format(0, 2)" color="#1a203b"></card-stats>
      </div>
    </div>

    <div class="row">

      <div class="col-md-12 mb-5">
        <!-- Performance Chart -->
        <div class="card h-100">
          <!-- Card Body -->
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h2 class="h4 card-header-title">
                  <span class="performance d-block">Transactions</span>
                </h2>
              </div>
              <div class="col-md-6">
                <nav aria-label="Page navigation example" class="float-left float-md-right">
                  <ul class="pagination">
                    <li class="page-item" :class="{ 'active': type === 'daily'}">
                      <a class="page-link" @click="setTransaction('daily')" href="javascript:void(0)">Daily</a>
                    </li>
                    <li class="page-item" :class="{ 'active': type === 'weekly'}">
                      <a class="page-link" @click="setTransaction('weekly')" href="javascript:void(0)">Weekly</a>
                    </li>
                    <li class="page-item" :class="{ 'active': type === 'monthly'}">
                      <a class="page-link" @click="setTransaction('monthly')" href="javascript:void(0)">Monthly</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <!-- Chart -->
            <div class="mx-auto mb-2">
              <apexchart :key="type" type="bar" height="350" :options="options"
                         :series="series">
              </apexchart>
            </div>
            <!-- End Chart -->
          </div>
          <!-- End Card Body -->
        </div>
        <!-- End Performance Chart -->
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/card-stats";
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";
import accounting from "accounting"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Doughnut from "@/components/chartjs/pie-chart";
import BarChart from "@/components/chartjs/bar-chart";

export default {
  components: {BarChart, DatePicker, CardStats, Doughnut },
  data() {
    return {
      profiles: 0,
      sms: 0,
      survey: 0,
      transaction: 0,
      accounting: accounting,
      time3:"",
      params: {
        start: '',
        end: '',
        graph: 'monthly'
      },
      days: 0,
      type: '',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Dataset 1',
            data: [],
            backgroundColor: [],
          }
        ]
      },
      data_bar: {
        labels: [],
        datasets: [
          {
            label: 'Sum Amount',
            data: [],
            borderColor: 'red',
            backgroundColor: 'red',
          }
        ]
      },
      series: [{ name: 'Net Profit', data: [] }],
      chartOptions: {
        labels: ['New', 'Returning']
      },
      options1: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              total: {
                show: true,
                label: 'TOTAL'
              }
            }
          }
        },
        labels: ['TEAM A', 'TEAM B', 'TEAM C', 'TEAM D']
      },
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'KES'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "KES " + val
            }
          }
        }
      },
      options2: {
        series: [{
          name: "Desktops",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      },
      loaded: false,
      key: '1',
      open: false,
      progress: false
    };
  },

  async mounted() {
    this.loaded = false
    await this.getStats(this.params)
    this.type = 'monthly'
    this.loaded = true
  },

  methods: {
    ...mapActions(["Stats"]),

    async getStats(params = null) {
      let response = await this.Stats(params)
      console.log("stats: " + JSON.stringify(response))
      this.profiles = response.message.profiles
      this.sms = response.message.sms
      this.survey = response.message.survey
      this.transaction = response.message.transaction.sum
      //graph
      this.options.xaxis.categories = response.message.graph.label
      this.series[0].data = response.message.graph.amount
    },
    async setTransaction(type){
      await this.getStats({ graph: type })
      this.type = type
    },
    searchRecords(val){
      this.params.start = moment().subtract(val, "days").format("YYYY-MM-DD")
      this.params.end = moment().format("YYYY-MM-DD")
      this.params.graph = this.type

      this.getStats(this.params)
      this.days = val
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    dateFilter(){
      if (this.time3[0] && this.time3[1]) {
        this.open = false
        this.params.start = this.format_date(this.time3[0])
        this.params.end = this.format_date(this.time3[1])
        this.params.graph = this.type

        this.getStats(this.params)
      }
      else this.getStats()
    },
    toggle () {
      this.days = 'custom'
      this.open = !this.open
    },
  }
}
</script>
<style scoped>
.mx-datepicker-popup {
  top: 100px !important;
  right: 0 !important;
  left: inherit !important;
}
.media-body {
  text-align: center;
}
</style>
