<template>
  <div class="u-body">
    <div class="row py-3">
      <div class="col-6">
        <h3 class="mb-2">Update System User</h3>
      </div>
      <div class="col-6 text-right">
        <router-link :to="{name: 'user'}" class="btn text-white bg-success active" role="button" aria-pressed="true">Users</router-link>
      </div>
    </div>
    <div class="add-users">
      <sui-form>
        <sui-form-fields fields="three">
          <sui-form-field>
            <label>First Name</label>
            <input placeholder="First Name" type="text" v-model="form.first_name"/>
          </sui-form-field>
          <sui-form-field>
            <label>First Name</label>
            <input placeholder="First Name" type="text" v-model="form.middle_name"/>
          </sui-form-field>
          <sui-form-field>
            <label>Last Name</label>
            <input placeholder="Last Name" type="text" v-model="form.last_name"/>
          </sui-form-field>
        </sui-form-fields>
        <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="editUser">Submit</button>
      </sui-form>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import { mapActions } from "vuex";
export default {
  data: function() {
    return {
      form: {
        id: null,
        mobile_number: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        permissions: null,
      },
      roles: [],
      items: [],
    };
  },
  components: {
    loader
  },
  methods: {
    ...mapActions(["updateUser", "getSystemRoles", "getSystemPermissions"]),
    async editUser() {
      let app = this

      const payload = this.form

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this updates this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateUser(payload)
        },
      })
          .then(async (result) => {
            console.log("result: " + JSON.stringify(result))
            if (result.value.status === 200) {
              app.$swal.fire('Updated!', result.value.message, 'success')
              await this.$router.push({name: 'user'})
              app.resetForm()
            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async setRoles() {
      let app = this
      let response = await this.getSystemRoles()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let role = { text: item.name, value: parseInt(item.id) }
          app.roles.push(role)
        })
      }
    },

    async setPermissions() {
      let app = this
      let response = await this.getSystemPermissions()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.items.push(list)
        })
      }
    },

    setForm: function () {
      this.form.id = this.$store.state.user.profile_id
      this.form.first_name = this.$store.state.user.first_name
      this.form.middle_name = this.$store.state.user.middle_name
      this.form.last_name = this.$store.state.user.last_name
    }
  },
  mounted() {
    //this.setRoles()
    //this.setPermissions()
    this.setForm()
  }
};
</script>
<style scoped>
</style>
