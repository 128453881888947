<template>
  <div class="row">
    <div class="col">
      <div class="btn-group btn-block">
        <router-link :to="{ name: 'user' }" :class="'btn btn-outline-dark ' + user">System Users</router-link>
        <router-link :to="{ name: 'roles' }" :class="'btn btn-outline-dark ' + roles">System Roles</router-link>
        <router-link :to="{ name: 'permissions' }" :class="'btn btn-outline-dark ' + permissions">System Permissions</router-link>
        <router-link :to="{ name: 'configs' }" :class="'btn btn-outline-dark ' + configs">System Configs</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inpage-navigation",
  props: {
    active: String // user | permission
  },
  data(){
    return {
      page: this.active,
      user: '',
      roles: '',
      permissions: '',
      configs: '',

    }
  },
  methods: {
    renderActive(){
      let active = this.page
      if (active === 'user') this.user = 'active'
      if (active === 'roles') this.roles = 'active'
      if (active === 'permissions') this.permissions = 'active'
      if (active === 'configs') this.configs = 'active'
    }
  },
  mounted() {
    this.renderActive()
  }
}
</script>

<style scoped>

</style>
