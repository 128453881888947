<template>
  <div>
  <!-- Tabs -->
  <div class="u-body">
    <inpage-navigation active="configs"></inpage-navigation>
    <div class="row pb-3 pt-5">
      <div class="col-6">
        <h3>System Configuration</h3>
      </div>
    </div>

    <!-- Card -->
    <div class="row">
      <!-- Crad Body -->
      <div class="col-12">
        <!-- Table -->
        <div class="table-responsive">
          <div class="">
            <loader v-show="loading"/>
            <vuetable ref="vuetable"
                      :api-mode="false"
                      :fields="fields"
                      :per-page="perPage"
                      track-by="id"
                      :data-manager="dataManager"
                      pagination-path="pagination"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded"
            >
              <template slot="actions" slot-scope="props">
                <sui-button v-show="getPermission('Update System Configurations')"  size="mini" color="blue" content="Edit" @click="toggleEdit(props.rowData)" />
              </template>
            </vuetable>
            <div style="padding-top:10px">
              <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!--  Modal | List -->
    <sui-modal v-model="edit">
      <sui-modal-header>Edit Configuration</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Value</label>
              <input placeholder="Value" type="text" v-model="form.value"/>
            </sui-form-field>
            <sui-form-field>
              <label>Description</label>
              <input placeholder="Description" type="text" v-model="form.type_desc"/>
            </sui-form-field>
            <sui-form-field>
              <label>Extra Data</label>
              <input placeholder="Extra Data" type="text" v-model="form.extra_data"/>
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Cancel </sui-button>
        <sui-button color="blue" @click.prevent="editRow"> Submit </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";

export default {
  components: {
    Vuetable,
    InpageNavigation,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "configs",

  data() {
    return {
      loading: true,
      edit: false,
      fields: [
        {
          name: "type",
          title: '<span class="orange glyphicon glyphicon-user"></span> Type',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "type_desc",
          title: '<span class="orange glyphicon glyphicon-user"></span> Description',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "value",
          title: '<span class="orange glyphicon glyphicon-user"></span> Value',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "extra_data",
          title: '<span class="orange glyphicon glyphicon-user"></span> Extra Data',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "created_at",
          title: "Date Created",
          formatter (value) { return moment(value).format('lll') },
          dataClass: 'no-border'
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        }
      ],
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
      permissions: [],
      items: [],
      form: {
        id: null,
        value: null,
        type_desc: null,
        extra_data: null,
      }
    };
  },

  mounted() {

    var vm = this;

    vm.setConfigs()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getSystemConfigs", "updateSystemConfig"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1){
        this.onLoaded()
        return;
      }

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setConfigs() {
      let response = await this.getSystemConfigs()
      console.log(JSON.stringify(response))
      this.data = response.message
    },

    toggleEdit(data) {
      this.form.id = data.id
      this.form.value = data.value
      this.form.type_desc = data.type_desc
      this.form.extra_data = data.extra_data
      this.edit = !this.edit
    },

    async editRow(){
      let app = this
      let payload = this.form
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this update this configuration!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateSystemConfig(payload)

        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                app.resetForm()
                await app.setConfigs()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm(){
      this.form.id = null
      this.form.type_desc = null
      this.form.value = null
      this.form.extra_data = null
      this.edit = false
    },

    getPermission(name){
      //let app = this
      if (this.$store.state.role === 1){
        return true
      }
      let permissions = this.$store.state.permission
      for (let item of permissions) {
        if (item.name === name) {
          return true
        }
      }
      return false
    }
  }
};
</script>
