<template>
  <nav id="sidebar">
    <!-- Header Logo -->
    <div class="u-header-left " style="width: 100%; background-color: #e72e4b">

      <a class="u-header-logo" href="/">
        <img class="u-header-logo__text" src="/img.png" alt="logo">
<!--        <img class="u-header-logo__text" src="https://placehold.jp/150x50.png" alt="logo">-->
      </a>
    </div>
    <!-- End Of Header Logo -->

    <!-- Menu Listing -->
    <ul class="u-sidebar-nav-menu u-sidebar-nav-menu--top-level">
      <!-- Dashboard -->
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'dashboard' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'dashboard'}">
          <span class="ti-dashboard u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Dashboard</span>
        </router-link>
      </li>
      <!-- End Dashboard -->

      <!-- Surveys -->
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'survey' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'survey' || this.$route.name === 'addQuiz'}">
          <span class="ti-settings u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title" v-if="client_id === 0">Surveys</span>
          <span class="u-sidebar-nav-menu__item-title" v-else>My Surveys</span>
        </router-link>
      </li>
      <!-- Surveys -->

      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'questionnaire' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'questionnaire'}">
          <span class="ti-pencil-alt u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Questionnaires</span>
        </router-link>
      </li>

      <!-- Transaction -->
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'transactions' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'transactions'}">
          <span class="ti-layout u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Transactions</span>
        </router-link>
      </li>
      <!-- End Transaction-->

      <!-- ratecard -->
      <li class="u-sidebar-nav-menu__item" v-show="client_id !== 0">
        <router-link :to="{ name: 'ratecard' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'ratecard'}">
          <span class="ti-layout u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Ratecard</span>
        </router-link>
      </li>
      <!-- End ratecard-->

      <!-- Customers -->
      <li class="u-sidebar-nav-menu__item" v-show="client_id !== 0">
        <router-link
            :to="{ name: 'customers' }"
            class="u-sidebar-nav-menu__link"
            :class="{'active': this.$route.name === 'customers' || this.$route.name === 'customer-bets' || this.$route.name === 'customer-mpesa' || this.$route.name === 'customer-trans' || this.$route.name === 'customer-withdraw'}">
          <span class="ti-server u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Customers</span>
        </router-link>
      </li>
      <!-- End Customers-->

      <!-- Surveys -->
<!--
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'report' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'report'}">
          <span class="ti-star u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Reports</span>
        </router-link>
      </li>
      -->

      <!-- System Users -->
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'user' }" class="u-sidebar-nav-menu__link"
                     :class="{'active': this.$route.name === 'user'
                     || this.$route.name === 'addUser'
                     || this.$route.name === 'roles'
                     || this.$route.name === 'editUser'}"
        >
          <span class="ti-user u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">System Users</span>
        </router-link>
      </li>

      <!-- Client -->
      <li class="u-sidebar-nav-menu__item" v-show="client_id === 0">
        <router-link :to="{ name: 'client' }" class="u-sidebar-nav-menu__link"
                     :class="{'active': this.$route.name === 'client'
                     || this.$route.name === 'addClient'
                     || this.$route.name === 'editClient'}"
        >
          <span class="ti-user u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">Clients</span>
        </router-link>
      </li>

      <!-- Users Logs -->
      <li class="u-sidebar-nav-menu__item">
        <router-link :to="{ name: 'userLogs' }" class="u-sidebar-nav-menu__link" :class="{'active': this.$route.name === 'userLogs'}">
          <span class="ti-archive u-sidebar-nav-menu__item-icon"></span>
          <span class="u-sidebar-nav-menu__item-title">User Logs</span>
        </router-link>
      </li>
    </ul>
    <!-- End Of Menu Listing -->
    <div @click.prevent="logout" style="bottom: 20px; position: fixed; padding-left: 4em">
      <sui-button basic negative fluid content="Log Out"></sui-button>
    </div>
  </nav>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "side-bar",
  data(){
    return {
      authorized: false,
      client_id: parseInt(this.$store.state.client_id)
    }
  },
  methods: {
    ...mapActions(["LogOut"]),

    async logout(){
      await this.LogOut();
    },

    getPermission(name){
      //let app = this
      if (this.$store.state.role === 1){
        return true
      }
      let permissions = this.$store.state.permission
      for (let item of permissions) {
        if (item.name === name) {
          //console.log('returning true: ' + name)
          return true
        }
      }
      //console.log('returning false: ' + name)
      return false

    }
  },
  mounted() {
    //this.getPermission()
  }
}
</script>

<style scoped>

</style>
