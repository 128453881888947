<template>
  <div>
    <div class="u-body" v-if="!this.sections[0].level || !this.sections[0].questionnaire_name">
      <!-- Card -->
      <div class="row d-flex align-items-center justify-content-center" style="height: 60em">
        <!-- Crad Body -->
        <div class="col text-center">
          <h1>Create a questionnaire</h1>
          <p><strong>Get feedback from your customers <br> and and improve your services</strong></p>
          <sui-button primary size="big" @click.prevent="toggleQuestion">Create Questionnaire</sui-button>
        </div>
      </div>
    </div>
    <div class="u-body" v-else>
      <transition name="fade">
        <sui-message
            v-if="visible"
            :content="message"
            dismissable
            @dismiss="handleDismiss"
            negative
        />
      </transition>
      <div class="col py-3">
        <h3>{{ sections[selected_section].questionnaire_name }} <sui-icon name="pencil alternate" style="cursor: pointer" @click.prevent="toggleQuestion"/></h3>
      </div>
      <!-- Card -->
      <div class="mb-5">
        <!-- Crad Body -->
        <div class="card-body">
          <h4>No of Questions {{ sections[selected_section].level }}</h4>
          <sui-segment v-for="(payload, index) in sections[selected_section].quizes" v-bind:key="index">
            <div class="row">
              <div class="col-1 text-center">
                <sui-button primary size="mini">Q {{ index + 1 }}</sui-button>
<!--                <p>{{ sections[selected_section][index].question }} || index {{ index }}</p>-->
              </div>
              <div class="col-11">
                <sui-form>
                  <sui-form-field>
                    <textarea rows="2" placeholder="Type your question here" v-model="payload.question"></textarea>
                  </sui-form-field>
                </sui-form>
                <div class="row pt-5">
                  <div class="col-md-3">
                    <sui-form>
                      <sui-form-field>
                        <sui-dropdown
                            placeholder="Type of question"
                            selection
                            v-model="payload.question_type_id"
                            :options="quiz_types"
                        />
                      </sui-form-field>
                    </sui-form>
                  </div>
                  <div class="col-md-9">
                    <!-- 1. Open Ended -->
                    <sui-form v-show="payload.question_type_id === 1">
                      <sui-form-fields fields="three">
                        <sui-form-field>
                          <input placeholder="User will enter text" type="text" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.qlink_to"
                              :options="qlinks"
                          />
                        </sui-form-field>
                        <sui-form-field v-show="payload.qlink_to !== null">
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.link_to"
                              :options="links[payload.qlink_to]"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                    </sui-form>
                    <!-- 2. Net Promoted Score(NPS) -->
                    <sui-form v-show="payload.question_type_id === 2">
                      <div class="row py-1" v-for="(item, counter) in payload.rating" v-bind:key="counter">
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Min" type="number" v-model="item.min"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Max" type="number" v-model="item.max"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-fields fields="two">
                            <sui-form-field>
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.qlink_to"
                                  :options="qlinks"
                              />
                            </sui-form-field>
                            <sui-form-field v-show="item.qlink_to !== null">
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.link_to"
                                  :options="links[item.qlink_to]"
                              />
                            </sui-form-field>
                          </sui-form-fields>
                        </div>
                        <div class="col py-1" v-show="counter === 0">
                          <sui-button positive size="mini" @click.prevent="addRating(index)">+</sui-button>
                        </div>

                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Min" type="number" v-model="item.min"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Max" type="number" v-model="item.max"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-fields fields="two">
                            <sui-form-field>
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.qlink_to"
                                  :options="qlinks"
                              />
                            </sui-form-field>
                            <sui-form-field v-show="item.qlink_to !== null">
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.link_to"
                                  :options="links[item.qlink_to]"
                              />
                            </sui-form-field>
                          </sui-form-fields>
                        </div>
                        <div class="col py-1" v-show="counter !== 0">
                          <sui-button negative size="mini" @click.prevent="deleteRating(index, counter)">X</sui-button>
                        </div>
                      </div>
                    </sui-form>
                    <!-- 3. Yes/No -->
                    <sui-form v-show="payload.question_type_id === 3">
                      <sui-form-fields fields="three">
                        <sui-form-field>
                          <input placeholder="Yes" type="text" value="Yes" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.yes.qlink_to"
                              :options="qlinks"
                          />
                        </sui-form-field>
                        <sui-form-field v-show="payload.yes.qlink_to !== null">
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.yes.link_to"
                              :options="links[payload.yes.qlink_to]"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                      <sui-form-fields fields="three">
                        <sui-form-field>
                          <input placeholder="No" type="text" value="No" disabled/>
                        </sui-form-field>
                        <sui-form-field>
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.no.qlink_to"
                              :options="qlinks"
                          />
                        </sui-form-field>
                        <sui-form-field v-show="payload.no.qlink_to !== null">
                          <sui-dropdown
                              placeholder="Link To"
                              selection
                              v-model="payload.no.link_to"
                              :options="links[payload.no.qlink_to]"
                          />
                        </sui-form-field>
                      </sui-form-fields>
                    </sui-form>
                    <!-- 7. Single Selection || 4. Multiple Selection -->
                    <sui-form v-show="payload.question_type_id === 7 || payload.question_type_id === 4">
                      <div class="row py-1" v-for="(item, counter) in payload.options" v-bind:key="counter">
                        <div class="col" v-show="counter === 0">
                          <sui-form-field>
                            <input placeholder="Option" type="text" v-model="item.option"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter === 0">
                          <sui-form-fields fields="two">
                            <sui-form-field>
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.qlink_to"
                                  :options="qlinks"
                              />
                            </sui-form-field>
                            <sui-form-field v-show="item.qlink_to !== null">
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.link_to"
                                  :options="links[item.qlink_to]"
                              />
                            </sui-form-field>
                          </sui-form-fields>
                        </div>
                        <div class="col py-1" v-show="counter === 0">
                          <sui-button positive size="mini" @click.prevent="addOptions(index)">+</sui-button>
                        </div>

                        <div class="col" v-show="counter !== 0">
                          <sui-form-field>
                            <input placeholder="Option" type="text" v-model="item.option"/>
                          </sui-form-field>
                        </div>
                        <div class="col" v-show="counter !== 0">
                          <sui-form-fields fields="two">
                            <sui-form-field>
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.qlink_to"
                                  :options="qlinks"
                              />
                            </sui-form-field>
                            <sui-form-field v-show="item.qlink_to !== null">
                              <sui-dropdown
                                  placeholder="Link To"
                                  selection
                                  v-model="item.link_to"
                                  :options="links[item.qlink_to]"
                              />
                            </sui-form-field>
                          </sui-form-fields>
                        </div>
                        <div class="col py-1" v-show="counter !== 0">
                          <sui-button negative size="mini" @click.prevent="deleteOption(index, counter)">X</sui-button>
                        </div>
                      </div>
                    </sui-form>
                    <sui-button class="float-right" negative size="mini" @click.prevent="deleteQuiz(index)">Delete</sui-button>
                  </div>
                </div>
              </div>
            </div>
          </sui-segment>
          <div class="row" v-show="sections[selected_section].level > sections[selected_section].quizes.length">
            <div class="col text-center"><strong style="cursor: pointer" @click="addQuiz"><sui-icon name="add" /> Add a Question</strong></div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div v-show="selected_section > 0" class="col text-left">
          <sui-button content="Previous Section" primary @click.prevent="selected_section--"></sui-button>
        </div>
        <div v-show="selected_section < (sections.length - 1)" class="col text-right">
          <sui-button content="Next Section" primary @click.prevent="selected_section++"></sui-button>
        </div>
      </div>
      <div class="row" v-show="selected_section === sections.length - 1">
        <div class="col-12">
          <sui-button class="text-center" content="Submit Questionnaire" positive fluid @click.prevent="submit"></sui-button>
        </div>
      </div>
    </div>

    <!--  Modal | question -->
    <sui-modal v-model="question" size="small">
      <sui-modal-header v-if="update">Update Questionnaire</sui-modal-header>
      <sui-modal-header v-else>Create a Questionnaire</sui-modal-header>
      <sui-modal-content>
        <transition name="fade">
          <sui-message
              v-if="visible"
              :content="message"
              dismissable
              @dismiss="handleDismiss"
              negative
          />
        </transition>
        <span>Fill in the details below</span>
        <sui-modal-description class="mt-2">
          <sui-form>
            <div class="row" v-for="(item, counter) in sections" v-bind:key="counter">
              <div class="col-md-1 text-center mt-5" v-show="counter === 0">
                <sui-button primary size="mini">{{ counter + 1 }}</sui-button>
              </div>
              <div class="col-md-5" v-show="counter === 0">
                <sui-form-field>
                  <label>Questionnaire Name</label>
                  <input placeholder="Questionnaire Name" type="text" v-model="item.questionnaire_name"/>
                </sui-form-field>
              </div>
              <div class="col-md-5" v-show="counter === 0">
                <sui-form-field>
                  <label>No of Questions</label>
                  <input placeholder="No of Sections" type="number" v-model="item.level"/>
                </sui-form-field>
              </div>
              <div class="col-md-1" v-show="counter === 0">
                <sui-form-field>
                  <sui-button positive size="mini" class="mt-4" @click.prevent="addSections">+</sui-button>
                </sui-form-field>
              </div>

              <div class="col-md-1 text-center mt-4" v-show="counter !== 0">
                <sui-button primary size="mini">{{ counter + 1 }}</sui-button>
              </div>
              <div class="col-md-5 mt-3" v-show="counter !== 0">
                <sui-form-field>
                  <input placeholder="Questionnaire Name" type="text" v-model="item.questionnaire_name"/>
                </sui-form-field>
              </div>
              <div class="col-md-5 mt-3" v-show="counter !== 0">
                <sui-form-field>
                  <input placeholder="No of Sections" type="number" v-model="item.level"/>
                </sui-form-field>
              </div>
              <div class="col-md-1 mt-3" v-show="counter !== 0">
                <sui-form-field>
                  <sui-button negative size="mini" @click.prevent="deleteSection(counter)">X</sui-button>
                </sui-form-field>
              </div>
            </div>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button class="float-left" @click.native="toggleQuestion" v-show="update"> Cancel </sui-button>
        <sui-button positive @click.native="add" v-if="update"> Update Questionnaire</sui-button>
        <sui-button positive @click.native="add" v-else> Create Questionnaire</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions, mapMutations} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker
  },
  name: "clients",

  data() {
    return {
      loading: true,
      question: true,
      schedule: false,
      visible: false,
      update: false,
      message: null,
      purposes: [
        { text: 'Data Collect', value: 1 },
        { text: 'Product Review', value: 2 }
      ],
      codes: [
        { text: '764562', value: 1 },
        { text: '678922', value: 2 }
      ],
      questions: [
        { text: 'First Questionnaire', value: 1 },
        { text: 'Second Questionnaire', value: 2 },
        { text: 'Third Questionnaire', value: 3 },
        { text: 'Fourth Questionnaire', value: 4 },
      ],
      types: [
        { text: 'Linked', value: 4 },
      ],
      selected_types: 4,
      quiz_types: [],
      time3:"",
      questionnaire_name: '',
      sections: [
        {
          questionnaire_name: null,
          level: null,
          quizes: [
            {
              question: null,
              question_type_id: null,
              unique_id: null,
              qlink_to: null,
              link_to: 1,
              options: [{ option: null, link_to: 1, qlink_to: null }],
              yes: { link_to: 1, qlink_to: null },
              no: { link_to: 1, qlink_to: null },
              rating: [{ min: 1, max: 10, link_to: 1, qlink_to: null }]
            }
          ]
        }
      ],
      selected_section: 0,
      links: [],
      qlinks: [],
      selected_links: 1
    };
  },

  mounted() {
    this.setQuizTypes()
  },

  methods: {
    ...mapActions(["fillSurvey", "addSurvey", "getQuizTypes", "addSurveyContact", "fillSurveySettingId",
      "getQuestionnaires", "fillQuestionnaire", "fillSurveyId"]),

    // options
    addOptions(index){
      this.sections[this.selected_section].quizes[index].options.push({option: null, link_to: 1, qlink_to: null })
    },
    deleteOption(index, counter){
      this.sections[this.selected_section].quizes[index].options.splice(counter,1);
    },

    // rating
    addRating(index){
      this.sections[this.selected_section].quizes[index].rating.push({ min: 1, max: 10, link_to: 1, qlink_to: null })
    },
    deleteRating(index, counter){
      this.sections[this.selected_section].quizes[index].rating.splice(counter,1);
    },

    // question
    addQuiz(){
      let length = this.sections[this.selected_section].quizes.length + 1
      let unique_id = this.links[this.selected_section][length].value
      this.sections[this.selected_section].quizes.push(
          {
            question: null,
            unique_id: unique_id,
            question_type_id: null,
            qlink_to: null,
            link_to: 1,
            options: [{ option: null, link_to: 1, qlink_to: null }],
            yes: { link_to: 1, qlink_to: null },
            no: { link_to: 1, qlink_to: null },
            rating: [{ min: 1, max: 10, link_to: 1, qlink_to: null }]
          }
      )
    },
    deleteQuiz(counter){
      this.sections[this.selected_section].quizes.splice(counter,1);
    },

    async setQuizTypes() {
      let app = this
      let response = await this.getQuizTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.quiz_types.push(list)
        })
      }
    },

    // generate links from level entered
    async generateLinks(){
      let app = this
      const timer = ms => new Promise(res => setTimeout(res, ms))
      for (let i = 0; i < this.sections.length; i++) {
        let link = [{ text: 'Next Quiz', value: 1}]
        for (let j = 0; j < app.sections[i].level; j++) {
          // links
          let timestamp = new Date().getTime();
          let val = parseInt(this.$store.state.client_id + '' + timestamp)
          console.log("val: " + val)
          let list = { text: 'Question '+(j+1), value: val }
          link.push(list)
          await timer(0)
        }
        app.links.push(link)
      }
    },

    async addSections(){
      let app = this
      let new_section = {
        questionnaire_name: null,
        level: null,
        quizes: [
          {
            question: null,
            question_type_id: null,
            unique_id: null,
            qlink_to: null,
            link_to: 1,
            options: [{ option: null, link_to: 1, qlink_to: null }],
            yes: { link_to: 1, qlink_to: null },
            no: { link_to: 1, qlink_to: null },
            rating: [{ min: 1, max: 10, link_to: 1, qlink_to: null }]
          }
        ]
      }
      app.sections.push(new_section)
    },

    deleteSection(counter){
      this.sections.splice(counter,1);
    },
    async add(){
      let app = this
      app.handleDismiss()

      // verify each section
      let errors = 0
      app.qlinks = []
      for (let i = 0; i < app.sections.length; i++) {
        let item = app.sections[i]
        if (!item.questionnaire_name || !item.level){
          errors++
        }
        let link = { text: item.questionnaire_name, value: i }
        app.qlinks.push(link)
      }
      if (errors > 0){
        return app.handleAlert('Fill all the required fields before submitting')
      }

      await Promise.all([this.generateLinks()])
      this.toggleQuestion()
    },

    toggleQuestion(update = null) {
      this.handleDismiss()
      if (update) this.update = true
      this.question = !this.question
    },

    async submit(){
      let app = this

      app.handleDismiss()
      // verify each quiz
      let errors = 0
      for (let i = 0; i < app.sections.length; i++) {
        app.sections[i].quizes[0].unique_id = app.links[i][1].value
        if (!app.sections[i].questionnaire_name || !app.sections[i].level){
          errors++
        }
        //console.log('section: ' + JSON.stringify(app.sections[i].quizes))
        for (let j = 0; j < app.sections[i].quizes.length; j++) {
          let item = app.sections[i].quizes[j]
          if (!item.question || !item.unique_id || !item.question_type_id){
            errors++
          }
        }
      }
      if (errors > 0){
        return app.handleAlert('Some of the questions contain empty fields. Fill all the questions before submitting')
      }
      /*
      if( document.getElementById("image").files.length === 0 ){
        return app.handleAlert('Contact file is missing')
      }
      */

      let survey1 = this.$store.state.survey
      survey1.quiz = app.sections
      await this.fillSurvey(survey1)

      let saved = this.$store.state.survey
      let payload = {
        app_name: saved.survey.survey_name,
        app_description: saved.survey.description,
        app_code: saved.survey.selected_codes,
        start_date: saved.survey.start_date,
        end_date: saved.survey.end_date,
        questionnaire: saved.quiz,
      }
      if (saved.survey.selected_incentives && saved.survey.selected_incentives_types){
        payload.reward_type_id = saved.selected_incentives
        payload.incentive_type_id = saved.selected_incentives_types
        payload.amount = saved.amount
      }
      console.log("payload: " + JSON.stringify(payload))
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a questionnaire!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addSurvey(payload)

        },
      })
          .then(async (result) => {
            if (result.value.status === 201) {
              //console.log(JSON.stringify(result.value))
              let id = parseInt(result.value.id)
              let setting_id = parseInt(result.value.setting_id)
              //await app.uploadFile(id, setting_id)
              app.$swal.fire({
                title: 'Added!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                app.resetForm()

                // redirect to settings
                await this.$router.push({name: 'report'})
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },

    async uploadFile(id, setting_id) {
      let app = this

      const payload = new FormData();
      payload.append('id', setting_id)
      payload.append('image', $('#image')[0].files[0])

      let res = this.addSurveyContact(payload);
      //console.log('file response: ' + JSON.stringify(res))

      await Promise.all([this.fillSurveySettingId(setting_id), this.setQuestionnaire(setting_id), this.fillSurveyId(id)])
    },

    async setQuestionnaire(setting_id) {
      let params = {setting_id: setting_id}
      let res = await this.getQuestionnaires(params);

      await this.fillQuestionnaire(res.message.data[0])
    },
    resetForm(){
      this.fillSurvey({})
    }
  }
};
</script>
