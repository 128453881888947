var render, staticRenderFns
import script from "./pie-chart.vue?vue&type=script&lang=js&"
export * from "./pie-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d9c584a",
  null
  
)

/* custom blocks */
import block0 from "./pie-chart.vue?vue&type=custom&index=0&blockType=Pie&%3Achart-data=chartdata&%3Aoptions=options&%3Astyles=styles&%3Awidth=width&%3Aheight=height"
if (typeof block0 === 'function') block0(component)

export default component.exports