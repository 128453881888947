<template>
  <div>
    <div class="u-body">
      <transition name="fade">
        <sui-message
            v-if="visible"
            :content="message"
            dismissable
            @dismiss="handleDismiss"
            negative
        />
      </transition>
      <sui-tab :menu="{ pointing: true }" :active-index.sync="index" @change="handleChange">
        <sui-tab-pane title="Survey" :attached="false">
          <sui-form>
            <sui-form-field>
              <label>Title</label>
              <input placeholder="Survey title" type="text" v-model="survey_name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Description</label>
              <textarea rows="2" placeholder="Survey Description" v-model="description"></textarea>
            </sui-form-field>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Period</label>
                <date-picker
                    placeholder="Survey Period"
                    style="width: 100%;"
                    class=""
                    v-model="time3"
                    range
                    @change="period"
                    :disabled-date="(date) => date <= new Date(new Date().setDate(new Date().getDate()-1))">
                </date-picker>
              </sui-form-field>
              <sui-form-field>
                <label>Logo</label>
                <input placeholder="Logo" type="text" v-model="logo"/>
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Select Mode</label>
                <sui-dropdown
                    placeholder="Short Code"
                    selection
                    v-model="channel_id"
                    :options="channels"
                />
              </sui-form-field>
              <sui-form-field v-if="codes.length > 0">
                <label>Short Code</label>
                <sui-dropdown
                    placeholder="Short Code"
                    selection
                    v-model="selected_codes"
                    :options="codes"
                />
              </sui-form-field>
              <sui-form-field v-if="channel_id === 2 && codes.length < 1">
                <label>Sender Name</label>
                <input placeholder="LIDENCO" value="LIDENCO" readonly type="text" v-model="selected_codes"/>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>
          <p class="mt-5 text-right"><sui-button primary @click.native="saveTab1"> Save </sui-button></p>
        </sui-tab-pane>
        <sui-tab-pane title="Questionnaire" :attached="false">
          <div v-if="!disabled_tab2">
            <p class="text-center" v-if="update"><strong>Update Questionnaire</strong></p>
            <p class="text-center" v-else><strong>Create a Questionnaire</strong></p>
            <sui-divider />
            <sui-form>
              <div class="row" v-for="(item, counter) in sections" v-bind:key="counter">
                <div class="col-md-1 text-center mt-5" v-show="counter === 0">
                  <sui-button secondary size="mini">{{ counter + 1 }}</sui-button>
                </div>
                <div class="col-md-5" v-show="counter === 0">
                  <sui-form-field>
                    <label>Questionnaire Name</label>
                    <input placeholder="Questionnaire Name" type="text" v-model="item.questionnaire_name"/>
                  </sui-form-field>
                </div>
                <div class="col-md-5" v-show="counter === 0">
                  <sui-form-field>
                    <label>No of Questions</label>
                    <input placeholder="No of Sections" type="number" v-model="item.level"/>
                  </sui-form-field>
                </div>
                <div class="col-md-1" v-show="counter === 0">
                  <sui-form-field>
                    <sui-button positive size="mini" class="mt-4" @click.prevent="addSections">+</sui-button>
                  </sui-form-field>
                </div>

                <div class="col-md-1 text-center mt-4" v-show="counter !== 0">
                  <sui-button secondary size="mini">{{ counter + 1 }}</sui-button>
                </div>
                <div class="col-md-5 mt-3" v-show="counter !== 0">
                  <sui-form-field>
                    <input placeholder="Questionnaire Name" type="text" v-model="item.questionnaire_name"/>
                  </sui-form-field>
                </div>
                <div class="col-md-5 mt-3" v-show="counter !== 0">
                  <sui-form-field>
                    <input placeholder="No of Sections" type="number" v-model="item.level"/>
                  </sui-form-field>
                </div>
                <div class="col-md-1 mt-3" v-show="counter !== 0">
                  <sui-form-field>
                    <sui-button negative size="mini" @click.prevent="deleteSection(counter)">X</sui-button>
                  </sui-form-field>
                </div>
              </div>
            </sui-form>
            <p class="mt-5 text-right"><sui-button class="" primary @click.native="saveTab2"> Save</sui-button></p>
          </div>
        </sui-tab-pane>
        <sui-tab-pane title="Questions" :attached="false">
          <div v-if="!disabled_tab3">
            <div class="col py-3">
              <h3>{{ sections[selected_section].questionnaire_name }}</h3>
            </div>
            <!-- Card -->
            <div class="mb-5">
              <!-- Crad Body -->
              <div class="card-body">
                <h4>No of Questions {{ sections[selected_section].level }}</h4>
                <sui-segment v-for="(payload, index) in sections[selected_section].quizes" v-bind:key="index">
                  <div class="row">
                    <div class="col-1 text-center">
                      <sui-button primary size="mini">Q {{ index + 1 }}</sui-button>
                      <!--                <p>{{ sections[selected_section][index].question }} || index {{ index }}</p>-->
                    </div>
                    <div class="col-11">
                      <div class="row">
                        <div class="col-md-9">
                          <sui-form>
                            <sui-form-field>
                              <textarea rows="2" placeholder="Type your question here" v-model="payload.question"></textarea>
                            </sui-form-field>
                          </sui-form>
                        </div>
                        <div class="col-md-3">
                          <sui-form>
                            <sui-form-field>
                              <textarea rows="2" placeholder="Optional placeholder e.g branch_name" v-model="payload.placeholder"></textarea>
                            </sui-form-field>
                          </sui-form>
                        </div>
                      </div>
                      <div class="row pt-5">
                        <div class="col-md-3">
                          <sui-form>
                            <sui-form-field>
                              <sui-dropdown
                                  placeholder="Type of question"
                                  selection
                                  v-model="payload.question_type_id"
                                  :options="quiz_types"
                              />
                            </sui-form-field>
                          </sui-form>
                        </div>
                        <div class="col-md-9">
                          <!-- 1. Open Ended -->
                          <sui-form v-show="payload.question_type_id === 1">
                            <sui-form-fields fields="three">
                              <sui-form-field>
                                <input placeholder="User will enter text" type="text" disabled/>
                              </sui-form-field>
                              <sui-form-field>
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.qlink_to"
                                    :options="qlinks"
                                />
                              </sui-form-field>
                              <sui-form-field v-show="payload.qlink_to !== null">
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.link_to"
                                    :options="links[payload.qlink_to]"
                                />
                              </sui-form-field>
                            </sui-form-fields>
                          </sui-form>
                          <!-- 2. Net Promoted Score(NPS) -->
                          <sui-form v-show="payload.question_type_id === 2">
                            <div class="row py-1">
                              <div class="col">
                                <sui-form-field>
                                  <sui-dropdown
                                      placeholder="NPS"
                                      selection
                                      v-model="selected_nps"
                                      :options="nps_scale"
                                  />
                                </sui-form-field>
                              </div>
                            </div>
                            <div v-if="selected_nps" class="row py-1" v-for="(item, counter) in payload.options" v-bind:key="counter">
                              <div class="col" v-show="counter === 0">
                                <sui-form-field>
                                  <sui-dropdown
                                      placeholder="Scale"
                                      selection
                                      v-model="item.option"
                                      :options="nps_details"
                                  />
                                </sui-form-field>
                              </div>
                              <div class="col" v-show="counter === 0">
                                <sui-form-fields fields="two">
                                  <sui-form-field>
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.qlink_to"
                                        :options="qlinks"
                                    />
                                  </sui-form-field>
                                  <sui-form-field v-show="item.qlink_to !== null">
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.link_to"
                                        :options="links[item.qlink_to]"
                                    />
                                  </sui-form-field>
                                </sui-form-fields>
                              </div>
                              <div class="col py-1" v-show="counter === 0">
                                <sui-button positive size="mini" @click.prevent="addOptions(index)">+</sui-button>
                              </div>

                              <div class="col" v-show="counter !== 0">
                                <sui-form-field>
                                  <sui-dropdown
                                      placeholder="Scale"
                                      selection
                                      v-model="item.option"
                                      :options="nps_details"
                                  />
                                </sui-form-field>
                              </div>
                              <div class="col" v-show="counter !== 0">
                                <sui-form-fields fields="two">
                                  <sui-form-field>
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.qlink_to"
                                        :options="qlinks"
                                    />
                                  </sui-form-field>
                                  <sui-form-field v-show="item.qlink_to !== null">
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.link_to"
                                        :options="links[item.qlink_to]"
                                    />
                                  </sui-form-field>
                                </sui-form-fields>
                              </div>
                              <div class="col py-1" v-show="counter !== 0">
                                <sui-button negative size="mini" @click.prevent="deleteOption(index, counter)">X</sui-button>
                              </div>
                            </div>
                          </sui-form>
                          <!-- 3. Yes/No -->
                          <sui-form v-show="payload.question_type_id === 3">
                            <sui-form-fields fields="three">
                              <sui-form-field>
                                <input placeholder="Yes" type="text" value="Yes" disabled/>
                              </sui-form-field>
                              <sui-form-field>
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.yes.qlink_to"
                                    :options="qlinks"
                                />
                              </sui-form-field>
                              <sui-form-field v-show="payload.yes.qlink_to !== null">
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.yes.link_to"
                                    :options="links[payload.yes.qlink_to]"
                                />
                              </sui-form-field>
                            </sui-form-fields>
                            <sui-form-fields fields="three">
                              <sui-form-field>
                                <input placeholder="No" type="text" value="No" disabled/>
                              </sui-form-field>
                              <sui-form-field>
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.no.qlink_to"
                                    :options="qlinks"
                                />
                              </sui-form-field>
                              <sui-form-field v-show="payload.no.qlink_to !== null">
                                <sui-dropdown
                                    placeholder="Link To"
                                    selection
                                    v-model="payload.no.link_to"
                                    :options="links[payload.no.qlink_to]"
                                />
                              </sui-form-field>
                            </sui-form-fields>
                          </sui-form>
                          <!-- 7. Single Selection || 4. Multiple Selection -->
                          <sui-form v-show="payload.question_type_id === 7 || payload.question_type_id === 4">
                            <div class="row py-1" v-for="(item, counter) in payload.options" v-bind:key="counter">
                              <div class="col" v-show="counter === 0">
                                <sui-form-field>
                                  <input placeholder="Option" type="text" v-model="item.option"/>
                                </sui-form-field>
                              </div>
                              <div class="col" v-show="counter === 0">
                                <sui-form-fields fields="two">
                                  <sui-form-field>
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.qlink_to"
                                        :options="qlinks"
                                    />
                                  </sui-form-field>
                                  <sui-form-field v-show="item.qlink_to !== null">
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.link_to"
                                        :options="links[item.qlink_to]"
                                    />
                                  </sui-form-field>
                                </sui-form-fields>
                              </div>
                              <div class="col py-1" v-show="counter === 0">
                                <sui-button positive size="mini" @click.prevent="addOptions(index)">+</sui-button>
                              </div>

                              <div class="col" v-show="counter !== 0">
                                <sui-form-field>
                                  <input placeholder="Option" type="text" v-model="item.option"/>
                                </sui-form-field>
                              </div>
                              <div class="col" v-show="counter !== 0">
                                <sui-form-fields fields="two">
                                  <sui-form-field>
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.qlink_to"
                                        :options="qlinks"
                                    />
                                  </sui-form-field>
                                  <sui-form-field v-show="item.qlink_to !== null">
                                    <sui-dropdown
                                        placeholder="Link To"
                                        selection
                                        v-model="item.link_to"
                                        :options="links[item.qlink_to]"
                                    />
                                  </sui-form-field>
                                </sui-form-fields>
                              </div>
                              <div class="col py-1" v-show="counter !== 0">
                                <sui-button negative size="mini" @click.prevent="deleteOption(index, counter)">X</sui-button>
                              </div>
                            </div>
                          </sui-form>
                          <sui-button class="float-right" negative size="mini" @click.prevent="deleteQuiz(index)">Delete</sui-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </sui-segment>
                <div class="row" v-show="sections[selected_section].level > sections[selected_section].quizes.length">
                  <div class="col text-center"><strong style="cursor: pointer" @click="addQuiz"><sui-icon name="add" /> Add a Question</strong></div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div v-show="selected_section > 0" class="col text-left">
                <sui-button content="Previous Section" primary @click.prevent="selected_section--"></sui-button>
              </div>
              <div v-show="selected_section < (sections.length - 1)" class="col text-right">
                <sui-button content="Next Section" primary @click.prevent="selected_section++"></sui-button>
              </div>
            </div>
            <div class="row" v-show="selected_section === sections.length - 1">
              <div class="col-12">
                <sui-button class="text-center" content="Save" positive fluid @click.prevent="saveTab3"></sui-button>
              </div>
            </div>
          </div>
        </sui-tab-pane>
        <sui-tab-pane title="Settings" :attached="false">
          <div v-if="!disabled_tab4">
            <sui-form>
              <sui-form-fields inline>
                <label>Do you want to activate rewards?</label>
                <sui-form-field>
                  <sui-checkbox radio v-model="incentive" name="rewards" label="YES" value="true" />
                </sui-form-field>
                <sui-form-field>
                  <sui-checkbox radio v-model="incentive" name="rewards" label="NO" value="false" />
                </sui-form-field>
              </sui-form-fields>
              <hr v-show="incentive === 'true'">
              <sui-form-fields fields="four" v-show="incentive === 'true'">
                <sui-form-field>
                  <label>Reward</label>
                  <sui-dropdown
                      placeholder="Reward"
                      selection
                      v-model="reward_type_id"
                      :options="incentives"
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Incentive</label>
                  <sui-dropdown
                      placeholder="Incentive"
                      selection
                      v-model="incentive_type_id"
                      :options="incentives_types"
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Incentive Amount</label>
                  <input placeholder="Incentive Amount" type="number" v-model="amount"/>
                </sui-form-field>
                <sui-form-field>
                  <label>Total Budget (KES)</label>
                  <input placeholder="Total Budget" type="number" v-model="totalBudget" disabled/>
                </sui-form-field>
              </sui-form-fields>
            </sui-form>
            <hr>
            <sui-form>
              <sui-form-field>
                <label>Opt In Message</label>
                <textarea rows="3" placeholder="Opt In Message" v-model="optin_message"></textarea>
              </sui-form-field>
              <sui-form-field>
                <label>Opt Out Message</label>
                <textarea rows="3" placeholder="Opt Out Message" v-model="optout_message"></textarea>
              </sui-form-field>
              <sui-form-field>
                <label>Completed Message</label>
                <textarea rows="3" placeholder="Opt Out Message" v-model="completed_message"></textarea>
              </sui-form-field>
              <sui-form-field>
                <label>Error Message</label>
                <textarea rows="3" placeholder="Opt Out Message" v-model="error_message"></textarea>
              </sui-form-field>
            </sui-form>
            <hr>
            <sui-form>
              <sui-form-field>
                <label>Upload Contacts</label>
                <input type="file" id="image" name="image" placeholder="Enter File" accept="text/csv" required>
              </sui-form-field>
              <div>
                <strong>Instructions: </strong><br>
                The file MUST be a <strong>CSV</strong> and in this specific format with these headers: <br>
                <p style="color: red; font-size: 20px">mobile, full_names</p>
                <a href="/assets/sample.csv" download>Download Sample</a>
              </div>
            </sui-form>
            <hr>
            <div class="row">
              <div class="col text-right">
                <sui-button positive content="Submit" @click.prevent="submit"></sui-button>
              </div>
            </div>
          </div>
        </sui-tab-pane>
      </sui-tab>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions, mapMutations} from "vuex";
import _, {split} from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";
import DatePicker from 'vue2-datepicker';

export default {
  components: {
    InpageNavigation,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    loader,
    DatePicker
  },
  name: "clients",

  data() {
    return {
      index: 0,
      loading: true,
      question: false,
      schedule: false,
      visible: false,
      update: false,
      message: null,
      selected_nps: null,
      purposes: [
        { text: 'Data Collect', value: 1 },
        { text: 'Product Review', value: 2 }
      ],
      codes: [],
      channels: [],
      nps_scale: [],
      nps_details: [],
      channel_id: 1,
      questions: [
        { text: 'First Questionnaire', value: 1 },
        { text: 'Second Questionnaire', value: 2 },
        { text: 'Third Questionnaire', value: 3 },
        { text: 'Fourth Questionnaire', value: 4 },
      ],
      types: [
        { text: 'Linked', value: 4 },
      ],
      selected_types: 4,
      quiz_types: [],
      time3:"",
      questionnaire_name: '',
      sections: [
        {
          questionnaire_name: null,
          level: null,
          quizes: [
            {
              question: null,
              question_type_id: null,
              selected_nps: null,
              unique_id: null,
              placeholder: null,
              qlink_to: null,
              link_to: 1,
              options: [{ option: null, link_to: 1, qlink_to: null }],
              yes: { link_to: 1, qlink_to: null },
              no: { link_to: 1, qlink_to: null },
              rating: [{ min: 1, max: 10, link_to: 1, qlink_to: null }]
            }
          ]
        }
      ],
      selected_section: 0,
      links: [],
      placeholders: [],
      qlinks: [],
      selected_links: 1,
      survey_name: null,
      description: null,
      logo: null,
      selected_purposes: null,
      selected_codes: '',
      dateRange: {
        start: "",
        end: ""
      },

      //tab 1
      selected_questionnaires: 'none',

      // tab 2
      disabled_tab2: true,

      // tab 3
      disabled_tab3: true,

      //tab 4
      disabled_tab4: true,
      incentive: 'false',
      incentives: [],
      reward_type_id: 1,
      incentives_types: [],
      incentive_type_id: 1,
      amount: null,
      totalBudget: null,
      optin_message: "Hello {name},\nLiden is doing an opinion poll on issues affecting Nairobi County.\nSMS NI FREE",
      optout_message: "You have successfully opted out of Liden opinion poll",
      completed_message: "Thank {name} you for participating in Liden opinion poll\nYour participation was of great value",
      error_message: "Sorry the survey has been stopped",
      allLinks: []
    };
  },

  mounted() {
    this.setQuizTypes()
    //this.setCodes()
    this.setChannels()
    this.setRewardTypes()
    this.setIncentiveTypes()
    this.setNpsScale()
  },
  watch: {
    channel_id(newVal, oldVal){
      this.setCodes()
    },
    selected_nps(newVal, oldVal){
      this.setNpsScale(this.selected_nps)
    }
  },

  methods: {
    ...mapActions(["fillSurvey", "addSurvey", "getQuizTypes", "addSurveyContact", "fillSurveySettingId",
      "getQuestionnaires", "fillQuestionnaire", "fillSurveyId", "getConfiguredChannels", "getSurveySettings",
      "updateSurveySettings", "startStopSurvey", "getRewardTypes", "getIncentiveTypes", "getChannels", "getNpsScale",
      "checkSurveyContact"]),
    ...mapMutations(["setContactCount"]),

    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    period(){
      this.dateRange.start = this.format_date(this.time3[0])
      this.dateRange.end = this.format_date(this.time3[1])
    },
    // options
    addOptions(index){
      this.sections[this.selected_section].quizes[index].options.push({option: null, link_to: 1, qlink_to: null })
    },
    deleteOption(index, counter){
      this.sections[this.selected_section].quizes[index].options.splice(counter,1);
    },

    // rating
    addRating(index){
      this.sections[this.selected_section].quizes[index].rating.push({ min: 1, max: 10, link_to: 1, qlink_to: null })
    },
    deleteRating(index, counter){
      this.sections[this.selected_section].quizes[index].rating.splice(counter,1);
    },

    // question
    async addQuiz() {
      // check placeholder
      let pl = await this.addPlaceholder();
      if (pl){
        this.handleAlert('Placeholder can only be one word. Correct to proceed');
        return
      }
      let length = this.sections[this.selected_section].quizes.length + 1
      let unique_id = this.links[this.selected_section][length].value
      this.sections[this.selected_section].quizes.push(
          {
            question: null,
            unique_id: unique_id,
            question_type_id: null,
            placeholder: null,
            selected_nps: null,
            qlink_to: null,
            link_to: 1,
            options: [{option: null, link_to: 1, qlink_to: null}],
            yes: {link_to: 1, qlink_to: null},
            no: {link_to: 1, qlink_to: null},
            rating: [{min: 1, max: 10, link_to: 1, qlink_to: null}]
          }
      )
    },
    deleteQuiz(counter){
      this.sections[this.selected_section].quizes.splice(counter,1);
    },
    async addPlaceholder(){
      // check if placeholders add to array
      let app = this
      let ret = false
      for (let i = 0; i < app.sections.length; i++) {
        for (let j = 0; j < app.sections[i].quizes.length; j++) {
          let holder = app.sections[i].quizes[j].placeholder
          if (holder){
            // check if single word
            if (holder.split(" ").length > 1){
              ret = true
              break
            }
            else {
              app.placeholders.push(holder)
            }
          }
        }
      }
      return ret
    },

    async setQuizTypes() {
      let app = this
      let response = await this.getQuizTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.quiz_types.push(list)
        })
      }
    },

    async setNpsScale(id = null) {
      let app = this
      if (id){
        let param = '?id=' + id
        let response = await this.getNpsScale(param)
        let details = response.message[0].details.split(',')
        app.nps_details = []
        for (let i = 0; i < details.length; i++) {
          let list = {text: details[i], value: details[i]}
          app.nps_details.push(list)
        }
      }
      else {
        let response = await this.getNpsScale()
        if (response.status === 200) {
          app.nps_scale = []
          response.message.forEach(function (item) {
            let list = {text: item.name, value: parseInt(item.id)}
            app.nps_scale.push(list)
          })
        }
      }
    },

    // generate links from level entered
    async generateLinks(){
      let app = this
      const timer = ms => new Promise(res => setTimeout(res, ms))
      app.links = []
      for (let i = 0; i < this.sections.length; i++) {
        let link = [{ text: 'Next Quiz', value: 1}]
        for (let j = 0; j < app.sections[i].level; j++) {
          // links
          let timestamp = new Date().getTime();
          let val = parseInt(this.$store.state.client_id + '' + timestamp)
          //console.log("sec: "+app.sections[i].level+" val: " + val)
          let list = { text: 'Question '+(j+1), value: val }
          link.push(list)
          await timer(0)
        }
        app.links.push(link)
      }

      /*
      // get total quizzes
      let totalQuiz = 0;
      for (let i = 0; i < this.sections.length; i++) {
        totalQuiz += parseInt(app.sections[i].level)
      }

      console.log('total quiz: ' + totalQuiz)
      for (let k = 0; k < this.sections.length; k++) {
        let specificLink = []
        for (let l = 0; l < totalQuiz; l++) {
          // generate links
          for (let i = 0; i < this.sections.length; i++) {
            let link = []
            for (let j = 0; j < app.sections[i].level; j++) {
              // generate links
              console.log("text: "+app.links[i][j].text+" val: " + app.links[i][j].value)
              let list = { text: app.links[i][j].text, value: app.links[i][j].value }
              link.push(list)
            }
            specificLink.push(link)
          }
        }
        app.allLinks.push(specificLink)
      }
      */

      // generate a link for each quiz
      for (let k = 0; k < this.sections.length; k++) {
        for (let l = 0; l < app.sections[k].level; l++) {
          // generate links
          let specificLink = []
          for (let i = 0; i < this.sections.length; i++) {
            let link = []
            let len = parseInt(app.sections[i].level) + 1
            for (let j = 0; j < len; j++) {
              // generate links
              console.log("text: "+app.links[i][j].text+" val: " + app.links[i][j].value)
              let list = { text: app.links[i][j].text, value: app.links[i][j].value }
              link.push(list)
            }
            specificLink.push(link)
          }
          app.allLinks.push(specificLink)
        }
      }

    },

    async addSections(){
      let app = this
      let new_section = {
        questionnaire_name: null,
        level: null,
        quizes: [
          {
            question: null,
            question_type_id: null,
            placeholder: null,
            selected_nps: null,
            unique_id: null,
            qlink_to: null,
            link_to: 1,
            options: [{ option: null, link_to: 1, qlink_to: null }],
            yes: { link_to: 1, qlink_to: null },
            no: { link_to: 1, qlink_to: null },
            rating: [{ min: 1, max: 10, link_to: 1, qlink_to: null }]
          }
        ]
      }
      app.sections.push(new_section)
    },

    deleteSection(counter){
      this.sections.splice(counter,1);
    },
    async saveTab2(){
      let app = this
      app.handleDismiss()

      // verify each section
      let errors = 0
      app.qlinks = []
      for (let i = 0; i < app.sections.length; i++) {
        let item = app.sections[i]
        if (!item.questionnaire_name || !item.level){
          errors++
        }
        let link = { text: item.questionnaire_name, value: i }
        app.qlinks.push(link)
      }
      if (errors > 0){
        return app.handleAlert('Fill all the required fields before submitting')
      }

      await Promise.all([this.generateLinks()])
      this.toggleQuestion()

      // activate tab 3
      app.disabled_tab3 = false
      app.index = 2
    },

    toggleQuestion(update = null) {
      this.handleDismiss()
      if (update) this.update = true
      //this.question = !this.question
    },

    async submit(){
      let app = this

      app.handleDismiss()
      // verify each quiz
      let errors = 0
      for (let i = 0; i < app.sections.length; i++) {
        app.sections[i].quizes[0].unique_id = app.links[i][1].value
        if (!app.sections[i].questionnaire_name || !app.sections[i].level){
          errors++
        }
        //console.log('section: ' + JSON.stringify(app.sections[i].quizes))
        for (let j = 0; j < app.sections[i].quizes.length; j++) {
          let item = app.sections[i].quizes[j]
          if (!item.question || !item.unique_id || !item.question_type_id){
            errors++
          }
        }
      }
      if (errors > 0){
        return app.handleAlert('Some of the questions contain empty fields. Fill all the questions before submitting')
      }
      if( document.getElementById("image").files.length === 0 ){
        return app.handleAlert('Contact file is missing')
      }

      // check headers
      const file_payload = new FormData();
      file_payload.append('image', $('#image')[0].files[0])
      let headers = await this.checkSurveyContact(file_payload)
      console.log('file res: ' + JSON.stringify(headers))
      if (headers.status !== 200){
        console.log('file error: ')
        return app.handleAlert(headers.message)
      }

      let survey1 = this.$store.state.survey
      survey1.quiz = app.sections
      await this.fillSurvey(survey1)

      let saved = this.$store.state.survey
      let payload = {
        app_name: saved.survey.survey_name,
        app_description: saved.survey.description,
        logo: saved.survey.logo,
        survey_channel_name: saved.survey.selected_codes,
        channel_id: saved.survey.channel_id,
        start_date: saved.survey.start_date,
        end_date: saved.survey.end_date,
        questionnaire: saved.quiz,
      }


      if (this.incentive === 'true'){
        if (app.amount < 1){
          return app.handleAlert('Amount must be greater than 0')
        }
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = app.amount
      }
      else {
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = 0
      }

      if (app.optin_message){
        payload.optin_message = app.optin_message
      }

      if (app.optout_message){
        payload.optout_message = app.optout_message
      }

      if (app.completed_message){
        payload.completed_message = app.completed_message
      }

      console.log("payload: " + JSON.stringify(payload))
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds this questionnaire!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addSurvey(payload)

        },
      })
          .then(async (result) => {
            /*
            app.$swal.fire({
              title: 'Processing!',
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                this.$swal.showLoading()
              },
            })
            */
            if (result.value.status === 201) {
              console.log(JSON.stringify(result.value))
              let id = parseInt(result.value.id)
              let setting_id = parseInt(result.value.setting_id)
              await app.uploadFile(id, setting_id)
              app.$swal.fire({
                title: 'Added!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                //app.resetForm()

                // go to survey page
                await this.$router.push({name: 'survey'})
              })

            }
            else if (result.value.status === 402) {
              // payment error
              app.$swal.fire({
                title: 'Payment Error!',
                text: result.value.message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Top Up!'
              }).then((result) => {
                if (result.isConfirmed) {
                  // activate top up modal
                }
              })
            }
            else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },
    handleDismiss() {
      this.visible = false;
    },
    handleAlert(message) {
      this.message = message
      this.visible = true
    },

    async uploadFile(id, setting_id) {
      let app = this

      const payload = new FormData();
      payload.append('id', setting_id)
      payload.append('image', $('#image')[0].files[0])

      let res = this.addSurveyContact(payload);
      //console.log('file response: ' + JSON.stringify(res))

      //await Promise.all([this.fillSurveySettingId(setting_id), this.setQuestionnaire(setting_id), this.fillSurveyId(id)])
    },

    async setQuestionnaire(setting_id) {
      let params = {setting_id: setting_id}
      let res = await this.getQuestionnaires(params);

      await this.fillQuestionnaire(res.message.data[0])
    },
    resetForm(){
      this.fillSurvey({})
    },

    handleChange(e, activePane, activeIndex) {
      let app = this
      // console.log('e: ' + e)
      // console.log('activePane: ' + activePane.title)
      // console.log('index: ' + activeIndex)
    },

    // tab 1
    async setCodes() {
      let app = this
      let payload = { survey_channel_id: app.channel_id }
      let response = await this.getConfiguredChannels(payload)
      if (response.status === 200){
        //let data = JSON.parse(response.message.data[0].survey_channel_name)
        console.log('channels: ' + JSON.stringify(response.message))
        app.codes = []
        if (response.message.total) {
          let data = split(response.message.data[0].survey_channel_name, ',');

          for (let i = 0; i < data.length; i++) {
            let list = {text: data[i].toString(), value: data[i]}
            app.codes.push(list)
          }
        }
      }
    },
    async saveTab1(){
      let app = this
      console.log('survey_name: ' + app.survey_name)
      console.log('selected_questionnaires: ' + app.selected_questionnaires)
      console.log('selected_codes: ' + app.selected_codes)
      if (app.channel_id === 2){
        app.selected_codes = 'LIDENCO'
      }
      if (!app.selected_codes){
        return app.handleAlert('Missing configured channel, contact admin for further assistance')
      }
      if (!this.survey_name || !this.channel_id || !app.selected_questionnaires || !app.selected_codes){
        return app.handleAlert('All mandatory fields are required')
      }
      let survey = {
        survey_name: app.survey_name,
        description: app.description,
        logo: app.logo,
        selected_questionnaires: app.selected_questionnaires,
        selected_purposes: app.selected_purposes,
        selected_codes: app.selected_codes,
        channel_id: app.channel_id,
        start_date: app.dateRange.start,
        end_date: app.dateRange.end,
      }
      /*
      if (this.incentive){
        if (!app.selected_incentives || !app.selected_incentives_types || parseFloat(app.amount) < 1){
          return app.handleAlert('All mandatory fields are required 2')
        }
        survey.selected_incentives = app.selected_incentives
        survey.selected_incentives_types = app.selected_incentives_types
        survey.amount = app.amount
      }
      */
      if (app.selected_questionnaires !== 'none'){
        // do something
      }
      else {
        let saved = this.$store.state.survey
        saved.survey = survey
        await this.fillSurvey(saved)
        // activate tab 2
        app.disabled_tab2 = false
        app.index = 1
        app.handleDismiss()
      }
    },

    // tab 3
    async saveTab3(){
      let app = this

      app.handleDismiss()
      // verify each quiz
      let errors = 0
      for (let i = 0; i < app.sections.length; i++) {
        app.sections[i].quizes[0].unique_id = app.links[i][1].value
        if (!app.sections[i].questionnaire_name || !app.sections[i].level){
          errors++
        }
        //console.log('section: ' + JSON.stringify(app.sections[i].quizes))
        for (let j = 0; j < app.sections[i].quizes.length; j++) {
          let item = app.sections[i].quizes[j]
          if (!item.question || !item.unique_id || !item.question_type_id){
            errors++
          }
        }
      }
      if (errors > 0){
        return app.handleAlert('Some of the questions contain empty fields. Fill all the questions before submitting')
      }

      let survey1 = this.$store.state.survey
      survey1.quiz = app.sections
      await this.fillSurvey(survey1)

      // activate tab 4
      app.disabled_tab4 = false
      app.index = 3
      app.handleDismiss()
    },

    async setForm() {
      let app = this
    },
    async saveSettings(action = null){
      let app = this

      app.handleDismiss()
      let payload = {
        id: this.$store.state.survey_setting_Id,
      }

      if (this.incentive === 'true'){
        if (app.amount < 1){
          return app.handleAlert('Amount must be greater than 0')
        }
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = app.amount
      }
      else {
        payload.reward_type_id = app.reward_type_id
        payload.incentive_type_id = app.incentive_type_id
        payload.amount = 0
      }

      payload.optin_message = app.optin_message
      payload.optout_message = app.optout_message
      payload.completed_message = app.completed_message
      payload.error_message = app.error_message

      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this saves the changes!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateSurveySettings(payload)
        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              if (action === 'start'){
                let payload = {
                  id: this.$store.state.survey_id,
                  action: 'start'
                }
                await app.startStopSurvey(payload)
              }
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                await app.setForm()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
      app.handleDismiss()
    },
    async setRewardTypes() {
      let app = this
      let response = await this.getRewardTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives.push(list)
        })
      }
    },
    async setChannels() {
      let app = this
      let response = await this.getChannels();
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.channels.push(list)
        })
      }
    },
    async setIncentiveTypes() {
      let app = this
      let response = await this.getIncentiveTypes()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.incentives_types.push(list)
        })
      }
    },
  }
};
</script>
sudo yum install php72-php-phalcon4
