<template>
  <div>
    <!-- Tabs -->
    <div class="u-body">
      <inpage-navigation active="roles"></inpage-navigation>
      <div class="row pb-3 pt-5">
        <div class="col-6">
          <h3>System Roles</h3>
        </div>
        <div v-show="getPermission('Create System Role')" class="col-6 text-right">
          <div class="btn btn-success text-uppercase mb-2 mr-2" @click="toggleAdd">Add Role</div>
        </div>
      </div>

      <!-- Card -->
      <div class="row">
        <!-- Crad Body -->
        <div class="col-12">
          <!-- Table -->
          <div class="table-responsive">
            <div class="">
              <loader v-show="loading"/>
              <vuetable ref="vuetable"
                        :api-mode="false"
                        :fields="fields"
                        :per-page="perPage"
                        track-by="id"
                        :data-manager="dataManager"
                        pagination-path="pagination"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
              >
                <template slot="permissions" slot-scope="props">
                  <sui-button v-if="props.rowData.permissions_list.length > 0" size="mini" color="green" content="View List" @click="permissionList(props.rowData.permissions_list)" />
                  <sui-button v-else size="mini" color="brown" content="No List" />
                </template>
                <template slot="actions" slot-scope="props">
                  <sui-button v-show="getPermission('Update System Role')"  size="mini" color="blue" content="Edit" @click="toggleEdit(props.rowData)" />
                </template>
              </vuetable>
              <div style="padding-top:10px">
                <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Modal | List -->
    <sui-modal v-model="open" size="small">
      <sui-modal-header>Permissions</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <div class="row">
            <div class="col-md-6">
              <sui-list bulleted>
                <sui-list-item v-for="list in permissions.slice(0, 9)" :key="list.id">{{ list.name }}</sui-list-item>
              </sui-list>
            </div>
            <div v-if="permissions.length > 9" class="col-md-6">
              <sui-list bulleted>
                <sui-list-item v-for="list in permissions.slice(10, 19)" :key="list.id">{{ list.name }}</sui-list-item>
              </sui-list>
            </div>
          </div>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="toggle"> Cancel </sui-button>
      </sui-modal-actions>
    </sui-modal>

    <!--  Modal | List -->
    <sui-modal v-model="add">
      <sui-modal-header>Add Role</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Role Name</label>
              <input placeholder="Role Name" type="text" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Role Description</label>
              <input placeholder="Role Description" type="text" v-model="form.description"/>
            </sui-form-field>
            <sui-form-field>
              <label>Permission</label>
              <sui-dropdown
                  placeholder="Permission"
                  selection
                  search
                  multiple
                  :options="items"
                  v-model="form.acl_list"
              />
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Cancel </sui-button>
        <sui-button color="blue" @click.prevent="addRole"> Submit </sui-button>
      </sui-modal-actions>
    </sui-modal>

    <!--  Modal | List -->
    <sui-modal v-model="edit">
      <sui-modal-header>Edit Role</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-form>
            <sui-form-field>
              <label>Role Name</label>
              <input placeholder="Role Name" type="text" v-model="form.name"/>
            </sui-form-field>
            <sui-form-field>
              <label>Role Description</label>
              <input placeholder="Role Description" type="text" v-model="form.description"/>
            </sui-form-field>
            <sui-form-field>
              <label>Permission</label>
              <sui-dropdown
                  placeholder="Permission"
                  selection
                  search
                  multiple
                  :options="items"
                  v-model="form.acl_list"
              />
            </sui-form-field>
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click.native="resetForm"> Cancel </sui-button>
        <sui-button color="blue" @click.prevent="editRow"> Submit </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import loader from "@/components/loader";
import {mapActions} from "vuex";
import _ from "lodash";
import moment from "moment";
import InpageNavigation from "@/components/tabs/inpage-navigation";

export default {
  components: {
    Vuetable,
    InpageNavigation,
    VuetablePagination,
    VuetablePaginationInfo,
    loader
  },
  name: "roles",

  data() {
    return {
      loading: true,
      open: false,
      add: false,
      edit: false,
      fields: [
        {
          name: "name",
          title: '<span class="orange glyphicon glyphicon-user"></span> Role Name',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "permissions",
          title: '<span class="orange glyphicon glyphicon-user"></span> Permissions',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "description",
          title: '<span class="orange glyphicon glyphicon-user"></span> Description',
          titleClass: "",
          dataClass: "no-border",
        },
        {
          name: "created",
          title: "Date Created",
          formatter (value) { return moment(value).format('lll') },
          dataClass: 'no-border'
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned no-border",
        }
      ],
      sortOrder: [
        {
          field: "created",
          direction: "desc"
        }
      ],
      moreParams: {
        start: "",
        filter: "",
        end: ""
      },
      perPage: 10,
      data: [],
      permissions: [],
      items: [],
      form: {
        id: null,
        name: null,
        description: null,
        acl_list: null,
      }
    };
  },

  mounted() {

    var vm = this;

    vm.setRoles()
    vm.setPermissions()
  },

  watch: {
    data(newVal, oldVal) {
      this.reload()
    }
  },

  methods: {
    ...mapActions(["getSystemRoles", "addSystemRole", "getSystemPermissions", "updateSystemRole"]),
    dateFilter: function(start, end) {
      var vm = this;

      vm.start = start;
      vm.end = end;

      vm.moreParams.start = start;
      vm.moreParams.end = end;

      this.$refs.vuetable.refresh();

      console.log("start date filter", start);
      console.log("end date filter ", end);
    },
    createdAt: function(value) {
      if (!value) {
        return "-";
      } else {
        return moment(value).format("h:mm a,DD MMM YYYY");
      }
    },

    reload(){
      this.$refs.vuetable.refresh();
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    onLoading() {
      this.loading = true;
      console.log("loading... show your spinner here");
    },
    onLoaded() {
      this.loading = false;
      console.log("loaded! .. hide your spinner here");
    },

    async setRoles() {
      let response = await this.getSystemRoles()
      this.data = response.message
    },

    toggle() {
      this.open = !this.open
    },

    toggleAdd() {
      this.add = !this.add
    },

    toggleEdit(data) {
      this.form.id = data.id
      this.form.name = data.name
      this.form.description = data.description
      this.form.acl_list = JSON.parse(data.acl_list)
      this.edit = !this.edit
    },

    async permissionList(list) {
      this.permissions = list
      this.open = true
    },

    async setPermissions() {
      let app = this
      let response = await this.getSystemPermissions()
      if (response.status === 200){
        response.message.forEach(function (item) {
          let list = { text: item.name, value: parseInt(item.id) }
          app.items.push(list)
        })
      }
    },

    async addRole(){
      let app = this
      let payload = this.form
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this adds a new role!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.addSystemRole(payload)

        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Added!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                app.resetForm()
                await app.setRoles()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    async editRow(){
      let app = this
      let payload = this.form
      app.$swal.fire({
        title: 'Are you sure?',
        text: "Doing this update this role!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: async (res) => {
          return await this.updateSystemRole(payload)

        },
      })
          .then(async (result) => {
            if (result.value.status === 200) {
              app.$swal.fire({
                title: 'Updated!',
                text: result.value.message,
                icon: 'success'
              }) .then(async (result) => {
                app.resetForm()
                await app.setRoles()
              })

            } else {
              app.$swal.fire('Error!', result.value.message, 'error')
            }
          })
    },

    resetForm(){
      this.form.name = null
      this.form.description = null
      this.form.acl_list = null
      this.add = false
      this.edit = false
    },
    getPermission(name){
      //let app = this
      if (this.$store.state.role === 1){
        return true
      }
      let permissions = this.$store.state.permission
      for (let item of permissions) {
        if (item.name === name) {
          return true
        }
      }
      return false
    }
  }
};
</script>
